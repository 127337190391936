.tag {
  padding: 2px 8px 2px 8px;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
  color: var(--color-main);
  background-color: var(--color-400);
  max-height: 28px;
  cursor: help;
  display: inline-flex;
  align-items: center;
}
