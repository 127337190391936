@import "src/styles/variables";


.adcard-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  background-color: var(--color-200);
  border-radius: 24px;

  &.small {
    display: flex;
    flex-direction: column;
    padding: unset;
  }

  &__root {
    padding: 2px 0px 0px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .icon--health-fill, .icon--verify-fill{
      position: relative;
      font-size: 18px;
      line-height: 15px;
      bottom: -4px;
      margin-right: 4px;
    }

    .button-s{
      text-wrap: nowrap;
    }
   

    &.small {
      padding: 12px;
      height: 100%;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    gap: 4px;
  }

  &__title {
    width: 110px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
  }

  &__progressbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    padding: 12px;
    background-color: var(--color-200);
    color: var(--color-main);
    font-weight: 600;

    &.not_active {
      opacity: 40%;
    }

    &-text {
      display: flex;
      flex-shrink: 0;
    }

    .progressbar {
      margin-left: 4px;
    }

    .progressbar-value {
      position: relative;
      background: var(--black-light);
      width: 100%;
      height: 8px;
      border-radius: 4px;
      outline: 1px solid #157a63;

      .progressbar-current {
        position: absolute;
        border-radius: 4px;
        left: 0;
        top: 0;
        height: 8px;
        background-color: #157a63;
      }
    }
  }

  &__toogle {
    display: flex;
    // grid-template-columns: 1fr auto 1fr;
    gap: 12px;

    justify-content: space-between;

    &.not_active {
      opacity: 40%;
    }
  }

  &__statistic {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 16px;
  }

  &__link {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;

    &.not_active {
      opacity: 40%;
    }
  }
}

.stat {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.vertical-line {
  border-left: 2px solid var(--color-300); /* Толщина и цвет линии */
  height: 100%;
}

.stories-block{
  height: 0px;
  position: relative;
  left: -77%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &:hover{
    opacity: 1;
  }
  @media (min-width: $pc) {
    left: -73%;
  }
  @media (max-width: $tablet) {
    height: fit-content;
    left: -0%;
    top: -0%;
    opacity: 1;
  }
}

.short-stat{
  width: 100%;
  .stat-table{
    display: grid;
    grid-template-columns: repeat(2,1fr);

    &__block{
      width: 200px;
      display: grid;
      grid-template-rows: repeat(2,1fr);
    }
  
    &__item{
      min-width: 150px;
      max-width: 150px;
      div{
        display: flex;
        align-items: center;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8px 0px;
    }
  }

  
}

.info-block-wrapper.adCard {
  margin-top: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}


.statistic-numbers-container {
  gap: 12px;
  color: var(--color-main);
  max-width: 100%;
  min-width: 50%;

  &__block {
    display: block;

    .balance-page__updown {
      margin-top: 12px;
      justify-content: space-between;
      font-size: 13px;
      color: var(--color-600);
      @media (max-width: $mobile) {
        font-size: 12px;
      }

      div {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }

  &__item2, &__item1{
    display: grid;
    gap: 8px;
    align-items: center;
    padding: 8px 12px;
    font-size: 15px;
    height: max-content;

    @media (max-width: $mobile) {
        font-size: 13px;
    }

    @media (max-width: $mobileExtraSmall) {
        min-height: 52px;
    }

    div {
      display: flex;
      align-items: center;
      gap: 8px;
      @media (max-width: $mobile) {
        gap: 4px;
      }
  
      i {
        font-size: 1.2em;
      }
  
      span {
        color: var(--color-600);
      }
    }

    span.numbers {
        text-align: end;
        line-height: 1;
    }

    span{
        color: var(--color-main);
    }

    
  }

  &__item2 {
    grid-template-columns: 5fr 1fr 1.3fr;
  }
  

  &__item1 {
    grid-template-columns: 5fr 1fr;
  }


  &__item1.adcard {
    width: 100%;
    font-size: 15px;

    @media (max-width: $mobile) {
      font-size: 14px;
    }

  }

  &__updown {
    display: flex;
    flex-direction: row;
    height: 40px;
    padding: 12px;
    align-items: center;
    // gap: 8px;
    border-radius: 4px;

    &.up {
      background-color: var(--green-300);
      justify-content: space-between;
      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    &.down {
      background-color: var(--red-300);
      justify-content: space-between;
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }
}
