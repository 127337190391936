@import "src/styles/variables";
@import "src/components/ui/tabs/tabs";

.special-label {
  display: none;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--color-200) !important;
}

.rdp-day__weekend {
  background-color: red !important;
}

.Toastify__toast-container {
  width: 390px !important;

  @media (max-width: $mobileSmall) {
    width: 360px !important;
    left: auto;
    padding-top: 10px;
    padding-left: 16px;
  }
}

.Toastify__toast-body {
  padding: 0 !important;
}

header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
  padding: 20px 0 0;
  color: var(--color-main);
  transition: color var(--switch-theme-time);
  background-color: transparent;
  @media (min-width: $mobile) {
    transition: all 0.15s ease 0s;
    border-bottom: 1px solid transparent;
    top: 0;
  }
  @media (max-width: $tablet) {
    padding: 40px 0 0;
  }
  @media (max-width: $mobileExtraSmall) {
    padding: 28px 0 0;
  }

  &._scroll {
    @media (min-width: $mobile) {
      z-index: 7;
      position: fixed;
      top: 0;
      padding: 0;
      border-bottom-color: var(--color-400);
      background-color: var(--bg-main);
      .user__save {
        display: none;
      }

      .logo {
        img {
          transform: translateY(-50%);
        }
      }

      .header__bottom,
      .header__top {
        padding: 14px 0;
      }
      .header__top {
        margin-bottom: 0;
      }
    }

    @media (min-width: $tablet) {
      top: -32px;
    }
  }

  .logo {
    margin-right: 54px;
    max-width: 244px;
    width: 100%;
    height: 40px;
    position: relative;
    @media (max-width: $pc) {
      margin-right: 0;
      max-width: 200px;
    }

    @media (max-width: $tablet) {
      max-width: 178px;
    }

    @media (max-width: $mobile) {
      height: 32px;
    }

    @media (max-width: $mobileExtraSmall) {
      max-width: 140px;
      position: absolute;
      left: calc(50% - 70px);
    }

    img {
      max-width: 100%;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-60%);
    }
  }

  .add-form {
    border: 1px solid transparent;

    i{
      font-size: 20px;
      line-height: 15px;
    }

    @media (max-width: $tablet) and (min-width: $mobile) {
      width: 40px;
      height: 40px;
      padding: 0;
      justify-content: center;
      outline: 1px solid var(--green-500)
    }
    @media (max-width: $mobile) {
      padding: 0px;
      min-width: 32px;
      min-height: 32px;
      i{
        font-size: 28px;
        line-height: 32px;
      }
      
      align-items: center;
      justify-content: center;
      i {
        width: 20px;
        height: 20px;
      }

      &:has(.icon.icon--plus){
        min-width: fit-content;
        width: 24px;
        border: 0px;
        padding: 0;
      }
      span {
        display: none;
      }
    }
  }
}

.select-city {
  .select__item {
    color: var(--color-600);
  }
  .select__arrow,
  .select__icon {
    pointer-events: none;
  }
}

.header {
  &__container {
    @media (max-width: $mobileSmall) {
    }

    .select-city {
      width: fit-content;
      justify-content: center;

      &.no-outline{
        outline: 0;
      }

      @media (max-width: $tablet) and (min-width: $mobile) {
        height: 40px;
        font-size: 12px;
        font-weight: 400;
      }
      @media (max-width: $mobile) {
        margin-right: auto;
      }
    }
  }

  &__top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
    gap: 16px;
    height: 32px;

    .add-form, .dropdown button, .select button, a:not(.logo,  .checkbox-link){
      font-size: 13px;
      padding: 6px;
      height: 32px;

      @media (max-width: $mobile) {
        font-size: 12px;
      }
    }

    .select button:first-child:not(.select__item){
      padding-right: 23px;
      i{
        font-size: 13px;
      }
    }
    
    .select button{
      height: 32px;
    }



    @media (max-width: $tablet) {
      justify-content: space-between;
      margin-bottom: 20px;
      gap: 10px;
    }
    @media (max-width: $mobile) {
      margin-bottom: 0;
    }
    
    @media (min-width: $mobileSmall) {
      a{
        &.button:not(.dropdown__body a){
          border: none;
          background-color: transparent;
          
          &:not(.button_outline--green){
          color: var(--color-800);
          &:hover{
            color: var(--color-main);
          }
          }
        }
        &:hover:not(.hover-line, .dropdown__body a ){
          background-color: transparent;
        }
      }
    }

    .center-screen{
      margin-left: calc(50% - 60px);
      margin-right: auto;

      @media (max-width: $mobileSmall) {
        margin-left: auto;
      } 
    }

    a:not(.add-form) {
      border-radius: 4px;
      text-decoration: none;
      justify-content: center;
    }
    a:not(.add-form, .hover-line, .logo, .dropdown__body a):hover{
      background-color: transparent;
      color: var(--color-main);
      i{
        color: var(--color-main);
      }
    }
    a.button_outline--green:hover{
      color: var(--green-accent);
      i{
        color: var(--green-accent);
      }
    }

    .header__language {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    gap: 10px;
    @media (max-width: $mobile) {
      justify-content: space-between;
      height: 32px;
    }
  }

  &__filter--place {
    .icon {
      pointer-events: none;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;

    .dropdown{
      button{
        padding: 12px 3px 12px 6px;
      }
    }

    .search{
      margin-right: 22px;
    }
    .header__filter{
      @media (max-width: $tablet) {
        margin-left: 0;
      }
    }
    
  }

  &__search--label {
    @media (max-width: $pc) {
      width: 170px;
    }
    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &__filter {
    position: relative;
    text-decoration: none;
    background-color: var(--color-300);
    transition: background-color var(--switch-theme-time),
      color var(--switch-theme-time);

    &:hover,
    &:focus-within {
      background-color: var(--green-500);
    }
  }

  &__reset {
    color: var(--color-600);
    transition: color 0.2s ease 0s;
    padding: 0;

    &:hover,
    &:focus-within {
      color: var(--color-800);
      background-color: transparent;
      text-decoration: underline !important;
    }

    @media (max-width: $pc) {
      margin-left: 4px;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    @media (max-width: $tabletSmall) {
      display: none;
    }
  }

  &__tab {
    padding: 7px 16px;
    color: var(--color-700);
    position: relative;
    transition: all 0.15s ease;
    width: max-content;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: var(--color-main);
      transition: inherit;
      transform: scale(0);
    }

    &:hover {
      color: var(--color-main);

      &:before {
        transform: scale(1);
      }
    }

    &:after {
      top: 2px;
      right: 4px;
    }

    &._active {
      color: var(--color-main);

      &:before {
        transform: scale(1);
      }
    }

    @media (max-width: $pc) {
      padding: 7px 10px;
    }

    @media (max-width: $tablet) {
      padding: 6px 6px;
    }

    @media (max-width: $tabletSmall) {
      padding: 8px 6px;
    }
  }

  &--type-two {
    @media (max-width: $mobile) {
      border-bottom: 1px solid var(--color-300);
      padding-bottom: 8px;
      ~ main {
        padding-top: 146px;
      }
    }

    .header__top {
      @media (max-width: $mobile) {
        margin-bottom: 20px;
      }
      @media (max-width: $mobileExtraSmall) {
        margin-bottom: 12px;
      }
    }

    .header__bottom {
      @media (max-width: $tablet) {
        justify-content: space-between;
      }
    }
  }
}

.header__tabs {
  position: relative;
  margin-left: 32px;
  height: 32px;
  transition: all 0.15s ease;
  width: max-content;
  display: flex;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: var(--color-400);
  }

  @media (max-width: $tablet) {
    margin-left: 8px;
  }

  a {
    height: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.user {
  &__wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-left: auto;
    position: relative;
    @media (max-width: $tablet) and (min-width: $mobile) {
      .button_outline--black {
        height: 40px;
      }
    }
    @media (max-width: $mobile) {
      margin-left: 0;
    }
  }

  &__notification,
  &__favorite {
    i:after {
      transform: translate(2px, -14px);
      background-color: var(--green-accent);
      color: var(--white-main);
    }
  }

  &__log-out {
    &:hover,
    &:focus-within {
      color: var(--color-600);
    }
  }
}

.users {
  transition: all var(--switch-theme-time);
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  background-color: var(--bg-main);
  padding: 40px 24px 30px 16px;
  max-width: 400px;
  width: 100%;
  z-index: 3;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  @media (max-width: $mobile) {
    right: auto;
    left: -100%;
    padding: 40px 16px 90px;
  }

  @media (max-width: $mobileExtraSmall) {
    max-width: 100%;
    padding: 16px 16px 90px;
  }

  &._active {
    right: 0;
    @media (max-width: $mobile) {
      right: auto;
      left: 0;
    }
  }

  &__nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    

      a.menu__link{
        height: 100px;
        i{
          font-size: 18px;
        }
      }

      &.top-nav{
        .user__favorite{
          background-color: var(--color-300);
          outline: none;
          i{
            background-color: transparent;
            color: var(--color-main);
            height: auto;
          }
        }
      }

    .menu__link {
      width: 100%;
      height: 100%;
      padding: 24px 0;
      font-size: 13px;
      border-radius: 4px;
      color: var(--green-main);

      &:hover,
      &:focus-within {
        color: var(--color-main);
        i:after {
          background-color: var(--green-main);
        }
      }

      @media (max-width: $mobile) {
        font-size: 12px;
      }
    }
  }
  &__nav.top-nav {
    .menu__link {
      color: var(--color-main);
      i{
        font-size: 18px;
      }
      &:hover,
      &:focus-within {
        color: var(--green-main);
      }
    }
    .menu__link.authorised_user {
      color: var(--green-main);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .button {
      font-size: 15px;

      &:hover,
      &:focus-within {
        color: var(--color-600);
      }
    }
  }

  &__bottom {
    margin: auto auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .user__log-out {
    color: var(--red-main);
    gap: 10px;
    min-height: 28px;
    font-size: 13px;

    i:after {
      background-color: var(--red-main);
    }
  }

  .icon-menu {
    @media (min-width: $mobile) {
      margin-left: auto;
    }
  }
}

.menu {
  &__body {
    position: fixed;
    bottom: 0;
    z-index: 5;
    background-color: var(--black-light);
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    //border-top: 1px solid var(--color-300);

    .menu__link{
      width: 100%;
      min-height: 60px;
      border-radius: 0%;
      background-color: transparent;
      padding: 0 !important;
      
      i{
        height: 24px;
        font-size: 18px;
      }

      span{
        //display: none;
        font-size: 12px;
        //text-transform: uppercase;
      }

      &.user__favorite{
        outline: none;
        color: var(--color-main);
        ::after{
          transform: translate(2px, 0px);
        }
        i{
          color: var(--color-main);
          height: 24px;
        }
       
      }
    }

    @media (min-width: $mobile) {
      display: none;
    }

    .menu__wallet {
      padding: 2px;
    }
  }

  &__link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    width: 56px;
    height: 56px;
    gap: 2px;
    min-height: auto;
    text-align: center;
    padding: 2px;
    background-color: var(--color-300);
    transition: color var(--switch-theme-time);
    outline: none;
    color: var(--color-main);
    border-radius: 4px;

    &:hover,
    &:focus-within {
      color: var(--green-main);
    }
  }

  &__wallet {
    padding: 6px 8px;
    color: var(--green-main);
    border-radius: 4px;
    border: 1px solid var(--color-200);
    font-size: 15px;
  }
}
.count {
  position: absolute;
  color: var(--color-main);
  top: -2px;
  right: -2px;
  width: 6px;
  height: 6px;
  background-color: var(--green-accent);
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.lower{
    top: 3px;
    right: 3px;
  }
}

.user__favorite{
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--green-400);
  color: var(--color-main);

  &:hover{
    background-color: var(--green-500);
    color: var(--color-main);

    @media (max-width: $mobile) {
      background-color: transparent;
    }
  }
  i{
    font-size: 15px;
  }
 

  @media (max-width: $tablet)  {
    border-radius: 4px;
    background-color: transparent;
    outline: 1px solid var(--green-500);
    color: var(--green-accent);
  }

  @media (max-width: $mobileSmall) {
    i{
      outline: none;
    }
  }
}
