@import "./src/styles/variables.scss";

.ver__check {
  display: flex;
  flex-direction: column;

  &__main {
    display: flex;
    flex-direction: row;
    gap: 16px;

    @media (max-width: $tabletSmall) {
      flex-direction: column;
    }
  }
}