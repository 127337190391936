@font-face {
    font-family: "Inter";
    font-display: swap;
    src: url("./Inter-Regular.woff2") format("woff2"), url("./Inter-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    font-display: swap;
    src: url("./Inter-Medium.woff2") format("woff2"), url("./Inter-Medium.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    font-display: swap;
    src: url("./Inter-SemiBold.woff2") format("woff2"), url("./Inter-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    font-display: swap;
    src: url("./Inter-ExtraBold.woff2") format("woff2"), url("./Inter-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Libertinus";
    font-display: swap;
    src: url("LibertinusSerif-Regular.woff2") format("woff2"), url("LibertinusSerif-Regular.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Libertinus";
    font-display: swap;
    src: url("LibertinusSerifDisplay-Regular.woff2") format("woff2"), url("LibertinusSerifDisplay-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "lady4love";
    src:url("./lady4love.eot");
    src:url("./lady4love.eot?#iefix") format("embedded-opentype"),
    url("./lady4love.woff") format("woff"),
    url("./lady4love.ttf") format("truetype"),
    url("./lady4love.svg#lady4love") format("svg");
    font-weight: normal;
    font-style: normal;
}