@import "./src/styles/variables.scss";

.notify {
    &__block{
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
        @media (max-width: $mobile) {
            flex-direction: row;
            align-items: center;
        }

        .error {
            position: absolute;
            left: 0;
            top: calc(100% - 8px);
            width: max-content;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 24px;

        @media (max-width: $mobile) {
            min-width: max-content;
            width: 100%;
            flex-direction: column;
            align-items: flex-end;
            gap: 14px;
        }
        .error{
            top: calc(100% - 0px);
        }
    }

    &__item{
        display: flex;
        align-items: center;
        gap: 8px;
        h3{
            margin: 0;
        }
    }
}