@import "src/styles/variables";

.user-badge{
    border-radius: 20px;
    background-color: var(--color-300);
    padding:4px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
    @media (max-width: $mobile) {
        gap: 4px;
        min-height: 28px;
    }

    .rating-stars{
        min-width: 60px;
    }

    span {
        white-space: nowrap;
        font-size: 18px;
        display: inline-block;
    }
}