@import "@/styles/variables";

@keyframes expandAnimD {
  0% {
    height: 0;
    opacity: 0;
    gap: 0;
    transform: scale(1, 0);
  }
  50%{
    height: auto;
    opacity: 0.1;
    gap: 6px;
    transform: scale(1, 0.5);
  }
  100%{
    height: auto;
    opacity: 1;
    gap: 12px;
    transform: scale(1, 1);
  }
}

@keyframes expandAnim {
  0% {
    height: 68px;
    opacity: 0;
    gap: 0;
    transform: scale(1, 0);
  }
  100%{
    height: auto;
    opacity: 1;
    gap: 12px;
    transform: scale(1, 1);
  }
}

@keyframes revexpandAnim {
  100% {
    height: 68px;
    opacity: 0;
    gap: 0;
    transform: scale(1, 0);
  }
  0%{
    height: auto;
    opacity: 1;
    gap: 12px;
    transform: scale(1, 1);
  }
}


.girl-card {
  position: relative;
  transition: all var(--switch-theme-time);
  background-color: transparent;
  width: 100%;
  min-width: fit-content;
  border-radius: 24px;

  &:has(.girl-card__wrapper.edit){
    height: fit-content;
  }


  &:has(.swiper-slide:hover){
    .girl-card__info .girl-card__name{
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;
    }
  }

  &__expand{
    margin-left: auto;
    background-color: var(--color-300);
    min-height: 40px;
    min-width: 40px;
    border-radius: 4px;
    i{
      transition: transform 0.3s ease-in-out;
    }
    &.expand{
      i{
        transform: rotate(180deg);
      }
    }
  }

  
  

  &.not_active {
    opacity: 40%;
    pointer-events: none;
    user-select: none;

    .checkbox {
      pointer-events: all;
    }
  }

  

  .show-hover{
    max-height: 0;
    opacity: 0;
    transform: scale(1, 0);
    transform-origin: top;
    transition: revexpandAnim 0.3s ease-in-out; 
      
    @media (max-width: $tablet) {
      transform: scale(1, 1);
      max-height: fit-content;
      opacity: 1;
      gap: 0;

      .girl-card__params, .girl-card__modes{
        height: 0px;
        transform: scale(1, 0);
        transition: all 0.3s ease-in-out;
      }

      .girl-card__buttons{
        margin-bottom: 16px;
        .button{
          min-width: 40px;
          min-height: 40px;
        }
      }
    }  
  }

  @media (max-width: $tablet) {
    .girl-card__wrapper.expand{
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      height: fit-content;
      z-index: 2;
      background: var(--black-light);
      box-shadow: 0px -4px 18px 0px var(--color-500);
      outline: 1px solid var(--color-500);
      transition: all 0.3s ease-in-out;
     
      &::after{
        position: absolute;
        transform: translate(0, 18px);
        z-index: 6;
      }

      .show-hover{
        height: auto;
        gap: 12px;
        animation: expandAnim 0.3s ease-in-out;
        .girl-card__modes, .girl-card__params{
          height: auto;
          transform: scale(1, 1);
        }
      }
  
      ~ .adcard-main__root .stories-block {
        opacity: 1;
      }
  }
  
}

@media (min-width: $tablet) {
  &:hover:not(.map-filter__wrapper .girl-card, .adcard-main .girl-card, .girlcard .girl-card) {
    background: var(--black-light);
    
    .girl-card__wrapper{
      position: absolute;
      top: 0;
      left: 0;
      height: fit-content;
      z-index: 3;
      background: var(--black-light);
      box-shadow: 0px -4px 18px 0px var(--color-500);
      outline: 1px solid var(--color-500);
      padding: 0 0 16px;

     

      &::after{
        position: absolute;
        transform: translate(0, 18px);
        z-index: 6;
      }
    }
    
    .show-hover{
      max-height: fit-content;
      transform: scale(1, 1);
      opacity: 1;
      pointer-events: all;
      animation: expandAnimD 0.3s ease-in-out; 
    }

    ~ .adcard-main__root .stories-block {
      opacity: 1;
    }
  }
}

.girl-card__wrapper.edit{
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      height: fit-content;
      z-index: 2;
      background: var(--black-light);
      //box-shadow: 0px -4px 18px 0px var(--color-500);
      outline: 1px solid var(--color-500);
      transition: all 0.3s ease-in-out;
      padding-bottom: 16px;
      &::after{
        position: absolute;
        transform: translate(0, 18px);
        z-index: 6;
      }
      .show-hover{
        height: 100%;
        max-height: 100%;
        transform: scale(1, 1);
        opacity: 1;
        gap: 12px;
        animation: expandAnim 0.3s ease-in-out;
        .girl-card__modes, .girl-card__params{
          
          height: auto;
          transform: scale(1, 1);
        }
        .girl-card__buttons{
          margin: 0;
        }
      }
  
      ~ .adcard-main__root .stories-block {
        opacity: 1;
      }

      &::after{
        display: none;
      }

      .girl-card__expand{
        display: none;
      }

      @media (max-width: $mobile) {
        outline: none;
      }
}

 

  &--elite {
    outline: 1px solid var(--gold-second);
    

    &:hover {
      .girl-card__wrapper{
        outline: 1px solid var(--gold-second);
      }
    }
  }

  &--top {
    outline: 1px solid var(--green-main);
    
    

    &:hover {
      .girl-card__wrapper{
        outline: 1px solid var(--green-main);
      }
    }
  }
  

  &__divider{
    margin: 0 12px;
  }

  &__wrapper {
    margin: 0;
    gap: 12px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    overflow: visible;
    height: 100%;
    color: var(--color-main);
    background: var(--black-light);
    transition: position 0.4s ease-in, height 0.3s ease-in-out;

    .swiper{
      border-radius: 24px 24px 0 0 ;
    }
    

    @media (max-width: $mobileSmall) {
      transition: all 0.3s ease-in-out;
    }
    
    // @media (max-width: $mobile) {
    //   grid-template-columns: 52% 1fr;
    // }

    // @media (max-width: $mobileSuperSmall) {
    //   grid-template-columns: 50% 1fr;
    // }

    &:after {
      position: absolute;
      width: 200px;
      height: 90px;
      left: calc(50% - 100px);
      top: -44px;
      z-index: 2;
      transition: all 0.3s ease-in-out;
    }

    .icon-square {
      cursor: help;
    }
   

    .girl-card--elite & {
      &:after {
        width: 200px;
        height: 66px;
        content: "";
        background: url("/assets/img/status/elite-mob.svg") no-repeat center
          center/ cover;
      }
    }
    

    .girl-card--top & {
      &:after {
        content: "";
        background: url("/assets/img/status/top-mob.svg") no-repeat center
          center/ contain;
      }
    }
  }

  .checkbox {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 2;
    width: 20px;
    height: 20px;
    display: inline-block;

    input {
      position: relative;
      width: 100%;
      height: 100%;
      transform: unset;
      left: unset;
      top: unset;
      border-color: var(--white-500);

      &:checked {
        background-color: var(--color-main);
        border-color: var(--color-main);

        &:after {
          background-color: var(--color-main-reverse);
        }
      }

      &:after {
        width: 14px;
        height: 14px;
      }
    }
    
  }

  &__info-wrapper, &__prices, &__group{
    padding: 0 12px;
  }

  &__info-wrapper{
    margin: 2px 0;
  }

  &__edit {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    color: var(--white-main);
    display: hidden;

    &:hover,
    &:focus-within {
      background-color: var(--color-500);
      color: var(--white-main);
    }
    
  }

  &__slider {
    aspect-ratio: 3/4;
    background-color: var(--color-main-reverse);
    width: 100%;
    min-width: 156px;
    height: fit-content;
    width: 100%;

    .swiper-slide {
      width: 100% !important;
    }

    &._edit {
      border-radius: 24px 24px 0 0;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      &:hover {
        .girl-card__edit {
          opacity: 1;
          visibility: visible;
        }

        .stories_wrapper {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .stories_wrapper {
      align-items: center;
      bottom: 100px;
      display: flex;
      gap: 8px;
      position: absolute;
      transition: all 0.3s ease-in-out;
      visibility: none;
      opacity: 0;
      width: 100%;
      z-index: 9;
      height: 120px;
      justify-content: center;

      @media (max-width: $tablet) {
        visibility: visible;
        opacity: 1;
        bottom: -0%;
      }
    }
  }

  &__favorite {
    position: absolute;
    z-index: 2;
    background-color: transparent;
    padding: 6px;
    color: var(--white-main);
    top: 12px;
    right: 12px;
    width: 36px;
    height: 36px;

    &:hover{
      color: var(--green-accent);
      background-color: transparent;
    }

    .icon {
      cursor: pointer;
      font-size: 18px;
    }

    .icon--favorite-fill {
      color: var(--green-main);
    }
  }

  &__remove {
    position: absolute;
    z-index: 5;
    top: 14px;
    right: 14px;
  }

  &__slide {
    cursor: pointer;
    background-color: var(--black-500);
    background-image: url("/assets/img/girl-card-bg--dark.webp");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .light & {
      background-image: url("/assets/img/girl-card-bg--light.webp");
    }

    .girl-card--top & {
      background-image: url("/assets/img/girl-card-bg--dark.webp");
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out 0s;
      transform: scale(1);
    }

    ._edit & {
      position: relative;
      height: 100%;
    }

    .girl-card__big & {
        &:before,
        &:after {
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          z-index: 1;
        }

        &:before {
          top: -36px;
          height: 100px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.9) 20%,
            rgba(0, 0, 0, 0) 100%
          );
        }

        &:after{
          bottom: -1px;
          height: 150px;
          background: linear-gradient(
            180deg,
            rgb(26, 26, 26, 0) 0%,
            var(--black-light) 100%
          );
        }

        
        &:last-of-type {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: unset;
        }
    }

    
  }

  .swiper-slide-active {
    &._hover,
    &:hover,
    &:focus-within {
      img {
        transform: scale(1.1);
      }
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 4px;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 8px;

    .button {
      span {
        display: none;
      }
    }

    .girl-card__big & {
      @media (min-width: $mobileSmall) {
        width: 212px;
        gap: 14px;
        padding: 16px;
        height: auto;
        border: 1px solid var(--color-300);
        .button {
          padding: 14px 4px;
          font-size: 15px;

          span {
            display: inline;
          }
        }
      }
    }
  }

  &__box__ads {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 4px;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 8px;
  }

  &__info-box {
    display: flex;
    gap: 2px;
    flex-direction: column;
    align-items: center;

    i{
      font-size: 22px;
      color: var(--color-main);
    }

    span {
      font-size: 13px;
      color: var(--color-main);
      @media (max-width: $mobile) {
        font-size: 12;
      }
    }

    .girl-card__big & {
      @media (min-width: $mobileSmall) {
        gap: 4px;
        i {
          font-size: 24px;
          width: 24px;
          height: 24px;
        }
        span {
          font-size: 15px;
        }
      }
    }
  }

  &__descr {
    padding: 0 8px 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;

    left: 0;
    right: 0;
    bottom: 0;

    
    .girl-card__tags-wrapper {
      display: flex;
      z-index: 2;
    }
    i {
      background-color: var(--black-600);
      &:first-of-type {
        margin-left: auto;
      }
    }
    .tag {
      font-size: 13px;
      background-color: var(--black-600);
      color: var(--white-main);
      @media (max-width: $mobile) {
        font-size: 12px;
      }
      
    }
  }

  &__info {
    width: 100%;

    div{
      display: flex;
      .rating{
        margin-left: auto;
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }
    

    &-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
      @media (max-width: $mobile) {
        justify-content: unset;
        gap: 8px;
      }

      .girl-card--top & {
        color: var(--white-main);
      }
      
    }

    a,
    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;

      i {
        vertical-align: text-top;
      }
    }

    a {
      text-decoration: none;

      &:hover,
      &:focus-within {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
        .girl-card--top & {
          color: var(--color-main);
        }
      }
    }

    .girl-card__big & {
      @media (min-width: $mobileSmall) {
        &:after {
          margin-top: -12px;
          transform: translateY(-56px);
          height: 0;
          z-index: 1;
        }
      }
    }
  }

  &__tags-wrapper {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 28px;
    overflow: hidden;
  }

  &__icon {
    background-color: var(--color-600);
    font-size: 20px;
    border-radius: 4px;
    color: var(--green-main);
  }

  &__name {
    display: flex !important;
    padding-left: 20px;
    max-width: 200px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    font-size: 24px;
    font-family: 'libertinus';

    &._hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;
    }
    
  }

  &__address {
    color: var(--color-700);
    padding-bottom: 2px;
    margin-bottom: -2px;
    position: relative;
    font-size: 15px;

    i {
      margin: 1px 6px 0 0;
      font-size: 15px;
    }
    
    @media (max-width: $mobile) {
      font-size: 12px;
    }
  }

  &__name i,
  &__address i {
    width: 12px;
    height: 12px;
    
  }

  &__age {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    text-align: center;
    padding: 8px;
    color: var(--color-main);
    background-color: var(--black-500);
    border-radius: 4px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    .light & {
      background-color: var(--black-200);
    }
    span {
      font-weight: 400;
      font-size: 13px;
      color: var(--color-700);
      line-height: 1;
    }

    @media (max-width: $mobile) {
      span{
        font-size: 12px;
      }
    }
  }

  &__group {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  &__params {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__param {
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 15px;
    white-space: nowrap;
    color: var(--color-main);

    

    &:last-child{
      margin-left: auto;
      min-width: 95px;
    }

    span {
      font-size: 13px;
      color: var(--green-secondary);
      font-weight: 400;
    }

    .flags{
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .icon-flag{
        margin-top: 2px;
      }
    }

    @media (max-width: $mobile) {
      font-size: 14px;

      span{
        font-size: 12px;
      }
    }
    
  }
  

  &__modes {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    max-height: 92px;
    overflow: hidden;

    i {
      background-color: var(--color-300);
      font-size: 20px;

      .girl-card--top & {
        background-color: var(--white-300);
      }
    }

    span {
      border-radius: 4px;
      height: 28px;
      line-height: 1;
      display: flex;
      align-items: center;
      font-size: 13px;
      padding: 6px;
      background-color: var(--color-400);

      .girl-card--top & {
        background-color: var(--white-400);
      }
    }
  }

  &__info-wrapper,
  &__advantages,
  &__buttons {
    grid-column: 1 / 3;
  }

  &__advantages {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: auto;
    @media (max-width: $mobileSmall) {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 8px;
    }
    .girl-card--top & {
      .rating-stars .rr--on .rr--svg {
        fill: var(--white-main);
      }

      .rating-stars .rr--hf-svg-off,
      .rating-stars .rr--off .rr--svg {
        fill: var(--white-500);
      }
    }
  }

  &__speak {
    display: flex;
    align-items: center;
    gap: 4px;
    line-height: 1;
    height: 16px;
    @media (min-width: $mobileSmall) {
      margin-left: auto;
    }
    span {
      display: inline-block;
      margin-right: 4px;
      color: var(--color-600);
    }
  }

  &__prices {
    display: flex;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    gap: 12px;
    flex-wrap: wrap;
    overflow: hidden;

    @media (max-width: $mobileSmall) {
      gap: 8px;
    }

    .disabled{
      .price-title{
        color: var(--color-600);
      }
      
    }

    > span {
      display: flex;
      flex-direction: column;
      line-height: 120%;
      font-size: 18px;
      font-weight: 600;
      gap: 2px;
      

      .price-title{
        opacity: 1;
        color: var(--green-secondary);
      }

      span {
        line-height: 110%;
        display: inline-block;
        color: var(--color-600);
        font-size: 13px;
        font-weight: 400;

        @media (max-width: $mobile) { 
          font-size: 12px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 8px;
    margin-top: auto;
    .icon {
      cursor: pointer;
    }
    .button:not(.button-icon){
      height: 40px;
      font-size: 15px;
      padding: 14px 25px;

      @media (max-width: $mobile) {
        font-size: 14px;
      }
    }

    .button:first-child {
      flex-grow: 1;
      text-align: center;
      min-height: 40px;
      border-radius: 20px;
    }
    .phone-button{
      display: block;
    }
    

    .phone-button span{
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in-out; 
    }

    .phone-button .phone {
      transform: translateY(100%); /* Initially hidden below */
      opacity: 0;
    }

    .phone-button.girl-card__phone .text{
      opacity: 0;
      transform: translateY(-100%);
    }
    .phone-button.girl-card__phone .phone{
      opacity: 1;
      transform: translateY(-12px);
    }
    
  }
  &__videochat,
  &__phone {
    @media (max-width: $mobile) {
      aspect-ratio: unset;
    }
  }

  &__videochat {
    + .girl-card__phone {
      width: 44px;
    }
  }
}

