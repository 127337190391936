@import "src/styles/variables";

.input-label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: text;
  border-radius: 4px;
  //background: var(--black-light);
 // background: var(--color-200);
  //transition: background-color var(--switch-theme-time);
  position: relative;
  justify-content: space-between;
  font-size: 13px;
  line-height: 1;
  border: 1px solid var(--color-400);
  padding: 12px 16px;

  @media (max-width: $mobile) {
    font-size: 12px;
  }
  &.error {
    border-radius: 0px;
    border-bottom: 1px solid var(--red-800);
  }
  

  .input-label__error {
    color: var(--red-700);
  }

  &:hover,
  &:focus-within {
    background: var(--color-400);
  }

  &:active {
    background: var(--black-light);
  }

  &__password {
    padding-right: 44px;
  }

  &__show-pass {
    position: absolute;
    right: 4px;
    bottom: calc((50% - 12px) * 0.5);
    background-color: transparent;
    width: 24px;
    height: 24px;
    color: var(--color-600);
    transition: color 0.15s ease-in-out 0s;
    &._show {
      color: var(--color-main);
    }
  }

  span {
    font-size: 13px;
    color: var(--green-secondary);

    &.disabled {
      color: var(--color-400);
    }

    &.error {
      color: var(--red-800);
    }
    @media (max-width: $mobile) {
      font-size: 12px;
    }
  }

  input,
  textarea {
    outline: none;
    background: transparent;
    width: 100%;
    color: var(--color-main);
    font-size: 15px;
    line-height: 140%;
    cursor: text;
    &.disabled {
      color: var(--color-500);
    }

    &::placeholder {
      color: var(--color-500);
    }
  }

  textarea {
    min-height: 68px;
  }

  &__radio {
    min-width: 75px;
    justify-content: center;
    padding: 8px 0;
    text-align: center;

    span {
      font-size: 13px;
      position: relative;
      z-index: 1;
      cursor: pointer;
      color: var(--color-main);
      transition: color 0.1s ease-in 0s;
      @media (max-width: $mobile) {
        font-size: 12px;
      }
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      appearance: none;
      border-radius: 4px;
      transition: background 0.1s ease-in 0s;

      &:checked {
        background: var(--color-main);
        ~ span {
          color: var(--color-main-reverse);
        }
      }
    }
  }
  &__checkbox {
    min-width: 60px;
    justify-content: center;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    color: var(--color-main);
    input {
      cursor: pointer;
      appearance: none;
      border: 1px solid var(--color-500);
      width: 16px;
      height: 16px;
      background: transparent;
      border-radius: 4px;
      transition: background 0.1s ease-in 0s;
      position: relative;
      &:before {
        color: transparent;
      }
      &:checked {
        &:before {
          color: var(--color-main);
        }
      }
    }
    label {
      position: relative;
      z-index: 1;
    }

    &.bg-none{
      &:hover, &:focus-within{
        background-color: transparent;
      }
    }
  }

  &--date {
    &,
    input {
      cursor: pointer;
    }
    &__body {
      position: absolute;
      left: 0;
      top: calc(100% + 8px);
      width: max-content;
      background-color: var(--black-light);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      z-index: 3;
      transition: all 0.3s;

      .my-selected:not([disabled]) {
        background-color: #1ca182;
        color: white;
      }

      ._active & {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &--number {
    & .input-price {
      height: 20px;
      display: flex;
      align-items: center;

      &:after {
        color: var(--color-600);
      }
    }
    &._active {
      
      .input-price:after {
        color: var(--color-main);
      }
    }
  }

  &--textarea {
    &.size {
      &-l {
        height: auto;
      }
    }
  }
}

.disabled {
  color: var(--color-400);
}
