@import "src/styles/variables";

.stories-circle {
    &__button {
        padding: 4px;
        border-radius: 50%;
        background-color: var(--bg-main);
        position: relative;
        margin-bottom: 20px;
        aspect-ratio: 1 / 1;
        width: 176px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media(max-width: $mobile) {
            width: 132px;
            margin-bottom: 14px;
        }

        &:hover, &:focus-within, &._hover {
            img{
                transform: scale(1.1);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(110deg);
            }

            ~ .stories-circle__name {
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 4px;
            }
        }

        &:after, &:before {
            position: absolute;
            content: "";
            transform: translate(-50%, -50%) rotate(0);
            left: 50%;
            top: 50%;
            border-radius: 50%;
        }

        &:after {
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgb(28, 161, 130);
            background: linear-gradient(125deg, rgba(28, 161, 130, 1) 0%, rgba(255, 255, 255, 1) 55%, rgba(226, 192, 104, 1) 100%);
            transition: all 0.2s ease-in;
        }

        &:before {
            width: 168px;
            aspect-ratio: 1 / 1;
            z-index: 2;
            background-color: var(--bg-main);
            @media(max-width: $mobile) {
                width: 126px;
            }
        }
    }

    &__image {
        width: 160px;
        aspect-ratio: 1 / 1;
        min-width: 160px;
        position: relative;
        display: block;
        border-radius: 50%;
        overflow: hidden;
        @media(max-width: $mobile) {
            width: 120px;
            min-width: 120px;
        }

        img {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
            object-fit: cover;
            border-radius: 50%;
            overflow: hidden;
            transform: scale(1);
            transition: all 0.2s ease 0s;
        }

        &._empty {
            @media(max-width: $mobile){
                i{
                    font-size: 20px;
                }
            }
        }
    }

    &__icon{
        position: absolute;
        z-index: 3;
        color: var(--white-main);
        border: 2px solid var(--color-main-reverse);
        background-color: var(--green-main);
        border-radius: 50%;
        bottom: -4px;
        right: -4px;
        width: 28px;
        height: 28px;
        @media (max-width: $mobile) {
            width: 24px;
            height: 24px;
            border: 1px solid var(--color-main-reverse);
        }
    }

    &__name {
        font-size: 24px;
        font-family: 'Libertinus';
        font-weight: 400;
        color: var(--color-main);
        line-height: 28.6px;
        margin-bottom: 5px;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
    }

    &__age {
        font-size: 13px;
        line-height: 19.2px;
        color: var(--color-700);
        font-weight: 600;
        @media(max-width: $mobile) {
            font-size: 12px;
        }
    }
}