@import "./src/styles/variables.scss";

.create-page__form.confirmation {
  display: flex;
  flex-direction: column;
  gap: 0px;

  .girl-card {
    width: 340px;
    flex-shrink: 0;
  }

  @media (max-width: $mobile) {
    .d-flex:first-of-type {
      flex-direction: column;
    }
  }
}
.form-confirmation {
  &__right {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__right {
    @media (max-width: $mobile) {
      display: flex;
      justify-content: center;
    }
  }

  &__right-top {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
  &__right--text {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;

    p {
      color: var(--color-800);
    }
  }

  &__toggle {
    display: flex;
    gap: 14px;
    padding: 16px;
    border-radius: 4px;
    background-color: var(--color-200);
    flex-direction: column;

    &-left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 12px;
      .button_outline--green-accent {
        text-decoration: none;

        .green::after {
          background-color: var(--green-main);
        }

        &:hover {
          .green::after {
            background-color: var(--color-main);
          }
        }
      }

      &.buttonIcon {
        min-width: 200px;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: var(--color-main);
    }

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }
}
span.green {
  color: var(--green-main);
}
