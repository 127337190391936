@import "../../styles/variables";

.notifications {
    background-color: var(--black-light);
    padding-top: 40px;
    position: absolute;
    z-index: 6;
    // top: calc(100% + 8px);
    top: -38px;
    right: 0;
    border-radius: 20px;
    //box-shadow: 0 12px 64px 0 var(--color-400);
    box-shadow: var(--box-shadow-main);
    padding: 32px 12px;
    width: 360px;
    @media (max-width: $tablet) {
        top: 0;
    }
    @media(max-width: $mobileExtraSmall){
        right: -16px;
    }
    
    .button-square{
        margin-top: 10px;
        justify-content: center;
        border: 1px solid var(--color-400);
    }
    .button__close{
        position: absolute;
        top: 2px;
        right: 8px;
        &:hover{
            background-color: transparent;
        }
    }
    .popup-form {
       
        &__textarea {
            padding: 16px;
            margin-bottom: 12px;
            border-radius: 4px;
            textarea {
                min-height: 48px;
                &::placeholder{
                    color: var(--color-500);
                }
            }
        }

    }
    .input-label__wrapper {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 12px;
        .input-label{
            margin-bottom: 0;
        }
    }

    button[type=submit] {
        margin-bottom: 0;
    }

    &__safe-filter{
        width: 100%;
        display: flex;
        gap: 8px;
    }

    &__main-button{
        max-height: 40px;
        background-color: var(--color-400);

        &:hover{
            background-color: var(--color-500);
        }

        @media(min-width: $tablet){
            width: max-content;
            z-index: 0;
            position: absolute;
            top: -38px;
            right: 0;
        }
        @media(max-width: $tablet){
            margin-left: auto;
            margin-right: 10px;
        }
        @media(max-width: $mobile){
            padding: 0;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            justify-content: center;
            margin-right: 0;
        }
        span{
            @media(max-width: $tablet){
                display: none;
                margin-left: auto;
            }
        }
    }

    &__text{
        font-size: 13px;
        line-height: 15px;
        color: var(--green-secondary);
        a{
            display: inline;
        }
    }

    &__subscribe{
        p{
            margin-top: 12px;
            text-align: center;
        }
        button[type=submit] {
            width: 100%;
            justify-content: center;
        }
    }
}