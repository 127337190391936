@import "src/styles/variables";

.res {
  &__grid{
    margin: 0;
    padding: 0;
    @media (max-width: $tabletSmall) and (min-width: $mobile){
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px 8px;
    }
  }

  &__root {
    display: grid;
    grid-template-columns: minmax(285px, auto) 1fr minmax(255px, auto);
    gap: 0 64px;
    align-items: start;
    background-color: var(--color-200);
    border-radius: 4px;
    padding: 24px;

    @media (max-width: $pc) {
      gap: 0 32px;
    }

    @media (max-width: $tablet) {
      display: flex;
    }

    @media (max-width: $tabletSmall) {
      display: flex;
      flex-direction: column;
      padding: 16px;
      align-items: unset;
      gap: 16px;
    }
  }

  &__user__main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &.decline {
      opacity: 40%;
    }
    // @media (max-width: $tablet) {
    //   flex-direction: row;
    //   justify-content: space-between;
    // }
    @media (max-width: $mobile) {
      gap: 12px;
    }
  }

  &__user__block {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
    //justify-content: space-between;
  }

  &__info__block {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $tablet) {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }

    &.decline {
      opacity: 40%;
    }

    .selected-models-count{
      min-width: 160px;
    }
  }

  &__info__block__money {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    column-gap: 16px;
    row-gap: 8px;
    width: 100%;
    @media(max-width: $tabletSmall){
      column-gap: 12px;
      row-gap: 4px;
      align-items: center;
      align-content: center;
    }
  }

  &__user-contacts{
    display: flex;
    align-items: start;

    @media (max-width: $tabletSmall) {
      align-items: stretch;
      width: 100%;
    }
    a{
      white-space: nowrap;
    }

    a.hover-line{
      padding: 6px 10px;
      min-height: 32px;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    span{
      margin: 0;
    }
  }

  &__var {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 8px;
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    gap: 10px;
    @media(max-width: $tabletSmall) {
      margin-top: auto;
    }
    .rating-stars{
      height: auto;
      max-width: 100px;
    }

    &__actions {
      display: flex;
      gap: 8px;

      @media (max-width: $tablet) and (min-width: $tabletSmall){
        flex-direction: column;
      }
    }

    &__text {
      @media (max-width: $tablet) and (min-width: $tabletSmall) {
        text-align: center;
      }
    }

    &.done {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }

    &.approve {
      @media(max-width: $tabletSmall){
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: space-between;
      }
      .res__status{
        flex-direction: column;
      }
    }

    &.decline{
      align-items: center;
      margin: auto;
    }

    &.work {
      gap: 12px;
      align-items: normal;

      @media (max-width: $tabletSmall) {
        .button--green{
          width: 100%;
        }
      }
    }

    &.done {
      flex-direction: column;
      @media (max-width: $tabletSmall) {
        flex-direction: row-reverse;
        align-items: flex-start;
      }
      .color-green{
        @media (max-width: $tabletSmall) {
          margin-top: auto;
        }
      }
      .res__status{
        flex-wrap: wrap;
        flex-direction: row;
        max-width: 280px;
        @media (max-width: $tabletSmall) {
          justify-content: flex-start;
        }
      }
      @media (max-width: $tabletSmall) {
        .button--green{
          width: 100%;
        }
      }
    }
  }

  &__ad {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__photo {
      width: 86px;
      object-position: center;
      object-fit: cover;
      height: 104px;
      border-radius: 4px;
    }

    span {
      width: 86px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
  }
}
