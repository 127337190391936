@import 'src/styles/variables';

.video{
    position: relative;
    overflow: hidden;
    height: 0;
    min-height: 388px;
    border-radius: 4px;
    @media(max-width: $mobileSmall){
        min-height: 198px;
    }

    &-button{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url("/assets/img/icons/triangle.svg") no-repeat center center / contain;
        width: 70px;
        height: 70px;
        z-index: 1;
    }

    video,
    iframe,
    object,
    embed {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}