.input-arrow{
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    background: var(--color-200);
    border-radius: 4px;
    padding: 4px;
    gap: 4px;
    min-width: 110px;
    justify-content: space-between;
    cursor: pointer;
    min-height: auto;
    .input-price:after{
        color: var(--color-main);
    }
    input{
        font-size: 18px;
        background-color: transparent;
        border: none;
    }
}