@import '/src/styles/variables';

.gallery{
    color: var(--color-main);
    padding: 0;
    z-index: 9;
    .button__close{
        z-index: 3;
        &:hover{
            transform: unset;
        }
    }

    &__items{
        border-radius: 0;
        border: 0;
        height: 100%;
        background: transparent;
    }

    &__item{
        height: 100%;
        display: flex;
        justify-content: center;
        padding: 30px 0;
        overflow: hidden;
        img{
            height: 100%;
            width: max-content;
            object-fit: contain;
            object-position: center;
        }
        .video{
            height: 100%;
        }
        video{
            object-fit: contain;
            position: relative;
        }
    }

    &__button{
        &-prev, &-next{
            transform: translateY(-50%);
        }

        &-prev{
            left: 16px;
        }

        &-next{
            right: 16px;
        }
    }

    &__numbers,
    &__caption{
        position: absolute;
        left: 0;
        right: 0;
        padding: 8px 10px;
        z-index: 2;
        background: var(--bg-main);
        display: inline-block;
    }

    &__numbers{
        top: 0;
    }

    &__caption{
        bottom: 0;
        text-align: center;
    }
}