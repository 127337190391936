@import "./src/styles/variables.scss";

.support-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    max-width: 800px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__filter {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
  }
}
.message-admin {
  padding: 8px 16px;
  background-color: var(--color-200);
  border-radius: 4px;
}
.popup-form__body.support-popup {
  .popup-form__inner {
    gap: 20px;
  }
}
