@import 'src/styles/root';


.publish-checkBox-wrap{
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    font-size: 15px;
    align-items: center;
    height: 60px;
    border: 1px var(--color-300) solid;
    div.checkbox:hover{
        background-color: transparent;
    }
    .publish-checkBox{
        background-color: transparent;
        label{
            padding: 0;
            padding-left: 28px;
            background: transparent;
            font-size: 15px;
        }
        input:checked ~ label{
            background-color: transparent;
        }
    }
    &.active{
        border: none;
        background-color: var(--color-300);
    }
    
}
