@import "./src/styles/variables";

.create-page__form.appearance {
  .select__button {
    min-width: 180px;
    @media (max-width: 1205px) {
      min-width: 130px;
    }
    @media (max-width: $mobile) {
      min-width: unset;
    }
  }
}
.loader-container  {
  width: 100%;
  height: 100%;
  background: var(--color-200);
  border-radius: 4px;
  .loader-wrapper {
    height: 80%;
    width: 100% !important;
  }
}
.penis-label {
  width: 61%;
}

.create-page__input-container {
  display: flex;
  .create-page__input-buttons {
    margin-top: 10px;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    padding: 0 12px;
    display: flex;
    gap: 8px;

    @media (max-width: 700px) {
      padding: 12px;
    }

    .checkbox-button {
      cursor: pointer;
      padding: 12px 25px;
      color: var(--color-600);
      background: var(--black-light);

      &:hover {
        color: var(--color-main);
        background-color: var(--color-400);
      }

      &.checkbox-button_checked {
        color: var(--color-main);
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    height: unset;
    align-items: stretch;
    justify-content: flex-end;
    .select {
      max-width: unset;
    }
  }
}
.create-page__form.appearance {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .first-row{
    display: grid;
    grid-template-columns: 1fr auto 1fr 1fr;

    @media (max-width: $mobile) {
      grid-template-columns: 1fr;
    }
  }

  .form-services__top-item {
    align-items: stretch;
  }

  .form-appearance {
    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      @media (max-width: 700px) {
        .input-label {
          grid-column: 1 / 4;
        }
      }
    }
    &__left {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
    &__right {
      padding: 12px 0px;
      align-self: flex-end;
      height: max-content;
      background-color: var(--color-300);
      border-radius: 4px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 16px;
      gap: 24px;
      img {
        width: 150px;
      }

      @media (max-width: $mobile) {
        margin: 0 auto;
        img {
          width: 200px;
        }
      }
    }
    &__top {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .input-label {
        width: 180px;
      }

      @media (max-width: 1345px) {
        .input-label {
          width: calc(50% - 8px);
        }
      }

      @media (max-width: 1345px) {
        flex-wrap: nowrap;
      }
    }

    &__top-main {
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }
    &__right-range {
      display: flex;
      flex-direction: column;
      gap: 12px;

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 64px;

      @media (max-width: $mobile) {
        top: 52px;
        gap: 34px;
      }
    }
    &__bottom {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      @media (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.create-range {
  .multi-range-slider {
    height: 2px;
    padding: 0px;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
  input {
    color: var(--color-main);
    width: 38px;
    height: 24px;
    text-align: center;
    border-radius: 4px 4px 0px 0px;
    padding: 6px 4px 8px 4px;
    background-color: var(--color-400);
  }

  .create-range__input {
    .thumb-left {
      display: none;
    }
    .bar-left {
      display: none;
    }
    .thumb-right::before {
      width: 6px;
      height: 6px;
      margin-top: -3px;
      margin-left: -4px;
      border: none;
      box-shadow: none;
      background-color: var(--color-main);
    }

    .bar-inner {
      background-color: var(--color-main);
      border-color: transparent;
    }

    .bar-right {
      box-shadow: none;
      background-color: var(--color-600);
    }

    .caption {
      display: none !important;
    }
  }
}
