@import "src/styles/variables";

.authorization {
    .popup-form {
        &__title{
            @media(max-width: $mobileExtraSmall){
                font-size: 24px;
            }
        }
        &__box {
            padding: 0;
            flex-direction: column;
            align-items: center;
            &-title{
                @media(max-width: $mobileExtraSmall){
                    font-size: 18px;
                }
            }

            .register-link{
                padding: 0;
                color: var(--green-secondary);
                min-height: auto;
                margin-top: 4px;

                &:hover{
                    text-decoration: underline !important;
                }
            }
        }

        &__body{
            .button__close{
                top: 20px;
                right: 20px;
            }
        }

        
    }

    &__forgot {
        margin: 0 auto 20px;
        color: var(--green-secondary);
        width: max-content;
        &:hover, &:focus-within {
            text-decoration: underline;
        }
    }
}

