@import "./src/styles/variables.scss";

.steps {
  &__progressbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    padding: 16px 12px;
    background-color: var(--black-light);
    color: var(--color-main);
    font-weight: 600;

    @media (max-width: $mobile) {
      flex-grow: 1;
    }

    &-text {
      display: flex;
      flex-shrink: 0;
    }

    .progressbar {
      margin-left: 4px;
    }

    .progressbar-value {
      position: relative;
      background: var(--black-light);
      width: 100%;
      height: 8px;
      border-radius: 4px;
      outline: 1px solid var(--green-400);

      .progressbar-current {
        position: absolute;
        border-radius: 4px;
        left: 0;
        top: 0;
        height: 8px;
        background-color: var(--green-another);
      }
    }
  }

  &__root {
    display: flex;
    gap: 24px;
    @media (min-width: $mobile) {
      flex-direction: column;
    }

    @media (min-width: $tabletSmall) {
      width: 320px;
    }

    @media (max-width: $tabletSmall) {
      flex-wrap: wrap;
    }
  }

  &__main {
    display: flex;
    gap: 12px;
    position: relative;
    width: 100%;
    &:has(.step._disabled){
      &:after{
        opacity: 0;
      }
    }
    &:after{
      content: '';
      position: absolute;
      left: 24px;
      top: 0;
      bottom: 0;
      width: 1px;
      background: var(--green-600);

      @media (max-width: $tabletSmall) {
          top: 24px;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
      }
    }

    @media (min-width: $tabletSmall) {
      flex-direction: column;
    }

    @media (max-width: $tabletSmall) {
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
  }
}

.step {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  @media (max-width: $tabletSmall) {
    flex-direction: column;
    width: auto;
  }

  &__current {
    display: flex;
    font-size: 15px;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--green-600);
    color: var(--green-600);
    background-color: var(--bg-main);
    z-index: 1;
    &.complete {
      background-color: var(--green-main);
      color: var(--color-main-reverse);
    }

    ._disabled & {
      border-color: var(--green-500);
      color: var(--green-500);
    }
  }

  &__text {
    font-size: 15px;
    font-weight: 600;
    @media (max-width: $tabletSmall) {
      display: none;
    }
    ._disabled & {
      color: var(--color-500);
    }
  }

  &._disabled {
    cursor: default;
  }

  &._active {
    color: var(--color-main);
  }

  &:hover {
    .step__text {
      text-decoration: underline;
    }
  }
}