@import "./src/styles/variables.scss";

.approve__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  border-radius: 24px;
  padding: 20px;
  height: 200px;
  width: 100%;
  background-color: var(--green-200);
}
