@import "src/styles/variables";

[data-counter] {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 10px;
    right: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 6px;
    padding: 0 2px;
    aspect-ratio: 1 / 1;
    line-height: 1;
    border-radius: 50%;
    background-color: var(--green-accent);
    color: var(--color-main-reverse);
    transition: color var(--switch-theme-time),
      background-color var(--switch-theme-time);
    font-size: 10px;
    &:empty {
      display: none;
    }
    @media (max-width: $tablet) {
      top: -4px;
      right: -6px;
    }
  }

  &.green {
    &:after {
      color: var(--white-main);
      background-color: var(--green-main);
    }
  }

  &.empty {
    &:after {
      display: none;
    }
  }
}

.button{
  
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  transition: all var(--switch-theme-time);
  font-size: 15px;
  text-decoration: none;
  background-color: transparent;
  height: 40px;
  line-height: 18px;
  padding: 12px 24px;
  min-height: 32px;
  @media (max-width: $mobile) {
    padding: 6px 12px;
    font-size: 14px;
    height: 36px;
    border-radius: 18px;
  }
  &:hover,
  &:focus-within {
    text-decoration: none !important;
    background-color: var(--color-400);
  }

  &-square{
    border-radius: 4px;
    padding: 10px;
    max-height: 40px;

    &-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      min-width: 40px;
      min-height: 40px;

      @media (max-height: $mobile) {
        min-height: 32px; 
        min-width: 32px;
        max-height: 32px;
      }
    }
  }

  &-icon{
    i.icon{
      width: 100%;
      height: 100%;
      font-size: 18px;
    }
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 50%;
    min-width: 40px;
    height: 40px;

    

    @media (max-width: $mobile) {
      border-radius: 50%;
      min-width: 36px;
      height: 36px;
    }

    &.button-l{
      border-radius: 50%;
      min-width: 44px;
      min-height: 44px;
      i{
        font-size: 18px;
      }
    }
  }
  

  
  &-l {
    height: 50px;
    border-radius: 26px;
    padding: 14px 48px;
    font-size: 18px;

    @media (max-width: $mobile) {
      font-size: 15px;
      height: 44px;
      padding: 14px 25px;
      border-radius: 22px;
    }
  }

  &-s{
    font-size: 13px;
    padding: 6px 12px;
    line-height: 15px;

    @media (max-width: $mobile) {
      font-size: 12px;
    }
  }


  &--black {
    background-color: var(--bg-main);
    color: var(--white-main);

    i:after {
      color: var(--white-main);
    }
  }

  &--primary {
    background-color: var(--color-700);
    color: var(--color-main-reverse);

    i:after {
      color: var(--color-main-reverse);
    }

    &:hover,
    &:focus-within {
      background-color: var(--color-main);
      color: var(--color-main-reverse);
    }
  }

  &--error {
    background-color: var(--red-300);
    color: var(--red-800);

    &:hover,
    &:focus-within {
      background-color: var(--red-500);
    }
  }

  &--secondary {
    background-color: var(--color-400);
    color: var(--color-main);

    &:hover,
    &:focus-within {
      background-color: var(--color-500);
      text-decoration: none;
    }
  }

  &--tetriary {
    background-color: var(--color-300);
    color: var(--color-800);

    &:hover,
    &:focus-within {
      background-color: var(--color-400);
    }
  }

  &--green {
    color: var(--white-main);
    background-color: var(--green-main);

    &-tp{
      background-color: var(--green-500);

      &:hover,
      &:focus-within {
        background-color: var(--green-700);
        text-decoration: none;
        color: var(--white-main);
      }
    }

    i:after {
      color: var(--white-main);
    }

    &:hover,
    &:focus-within {
      background-color: var(--green-accent);
      text-decoration: none;
      color: var(--white-main);
    }
  }

  &--green__secondary {
    color: var(--white-main);
    background-color: var(--green-400);

    i:after {
      color: var(--white-main);
    }

    &:hover,
    &:focus-within {
      background-color: var(--green-700);
      color: var(--white-main);
    }
  }

  &--green-accent {
    color: var(--white-main);
    background-color: var(--green-accent);

    i:after {
      color: var(--white-main);
    }

    &:hover,
    &:focus-within {
      color: var(--white-main);
      opacity: 0.7;
    }
  }

  &_outline {
    &--black {
      border: 1px solid var(--color-400);
      color: var(--color-800);

      &:hover,
      &:focus-within {
        background-color: var(--color-400);
      }
    }

    &--green {
      color: var(--green-main);
      border: 1px solid var(--green-main);
      background-color: transparent;
      i:after {
        color: var(--green-main);
      }

      &:hover,
      &:focus-within {
        border: 1px solid var(--green-700);
        background-color: var(--green-200);
        color: var(--green-main);
      }
    }

    &--green-accent {
      background-color: transparent;
      color: var(--green-main);
      border: 1px solid var(--green-main);
      &:hover,
      &:focus-within {
        background-color: var(--green-main);
        color: var(--white-main);
        text-decoration: none;
      }
    }

    &--spec-green {
      background: transparent;
      color: var(--green-main);
      border: 1px solid var(--green-main);
      background-blend-mode: color, normal;
      &:hover,
      &:focus-within {
        background: linear-gradient(0deg, #00a480, #00a480),
          linear-gradient(
            76.82deg,
            #546265 11.6%,
            #9da1a1 25.31%,
            #828b8a 48.06%,
            #546265 55.72%,
            #546265 77.23%,
            #747a7b 85.34%,
            #546265 91.31%
          );
        color: var(--white-main);
      }
    }

    &--spec-yellow {
      background: transparent;
      color: var(--gold-main);
      border: 1px solid var(--gold-main);
      background-blend-mode: color, normal;
      &:hover,
      &:focus-within {
        background: linear-gradient(
          49.58deg,
          #b07d20 39.69%,
          #b07d20 59.3%,
          #ffda44 84.29%,
          #ffda44 104.7%,
          #b07d20 122.24%,
          #b07d20 148.51%
        );
        color: var(--white-main);
      }
    }
  }

  &_no-fill {
    background-color: transparent;
    padding: 14px 25px;
    

    &__hover {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &._disabled,
  &[disabled] {
    filter: grayscale(0.5);
    opacity: 0.4;
    pointer-events: none;
  }

  &._no-underline {
    text-decoration: none;
  }

  &__close{
    padding: 0;
  }
}


.no-hover-bg:hover{
  background: transparent !important;
}



.spinner_root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 24px;
  height: 24px;

  &.spinner-s {
    width: 18px;
    height: 18px;
  }

  & .path {
    stroke: var(--color-main);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

