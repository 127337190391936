@import './src/styles/variables';

.agencies{
    padding-bottom: 52px;
    &__container{
        .navigation__top{
            gap: 16px;
        }
        .navigation__top-wrapper > a{
            display: flex;
        }
    }
    h1{
        display: flex;
        align-items: baseline;
        gap: 16px;
    }
    &__count{
        font-size: 24px;
        color: var(--color-600);
        @media(max-width: $mobile){
            font-size: 24px;
        }
    }
    &__map{
        @media(max-width: $tabletSmall){
            display: flex !important;
        }
        @media(max-width: $mobileExtraSmall){
            width: 100%;
            justify-content: center;
        }
    }
}