.icon-flag {
    display: inline-block;
    cursor: help;
    width: 17px;
    height: 13px;
    min-width: 17px;
    min-height: 13px;
    background: url('/assets/img/flag-sprite.png') no-repeat top left;

    &-m {
        width: 28px;
        height: 20px;
        min-width: 28px;
        min-height: 20px;
        background: url('/assets/img/flag-sprite-big.png') no-repeat top left;
    }

    //флаги
    &.AD {
        background-position: 0 0;

        &.icon-flag-m {
            background-position: 0 0;
        }
    }

    &.AE {
        background-position: 0 -19px;

        &.icon-flag-m {
            background-position: 0 -40px;
        }
    }

    &.AF {
        background-position: 0 -40px;

        &.icon-flag-m {
            background-position: 0 -60px;
        }
    }

    &.AG {
        background-position: 0 -80px;

        &.icon-flag-m {
            background-position: 0 -90px;
        }
    }

    &.AI {
        background-position: 0 -100px;

        &.icon-flag-m {
            background-position: 0 -120px;
        }
    }

    &.AL {
        background-position: 0 -100px;

        &.icon-flag-m {
            background-position: 0 -150px;
        }
    }

    &.AM {
        background-position: 0 -120px;

        &.icon-flag-m {
            background-position: 0 -180px;
        }
    }

    &.AN {
        background-position: 0 -160px;

        &.icon-flag-m {
            background-position: 0 -210px;
        }
    }

    &.AO {
        background-position: 0 -180px;

        &.icon-flag-m {
            background-position: 0 -240px;
        }
    }

    &.AR {
        background-position: 0 -180px;

        &.icon-flag-m {
            background-position: 0 -270px;
        }
    }

    &.AT {
        background-position: 0 -200px;

        &.icon-flag-m {
            background-position: 0 -300px;
        }
    }

    &.AU {
        background-position: 0 -220px;

        &.icon-flag-m {
            background-position: 0 -330px;
        }
    }

    &.AW {
        background-position: 0 -261px;

        &.icon-flag-m {
            background-position: 0 -360px;
        }
    }

    &.AX {
        background-position: 0 -281px;

        &.icon-flag-m {
            background-position: 0 -390px;
        }
    }

    &.AZ {
        background-position: 0 -281px;

        &.icon-flag-m {
            background-position: 0 -420px;
        }
    }

    &.BA {
        background-position: 0 -301px;

        &.icon-flag-m {
            background-position: 0 -450px;
        }
    }

    &.BB {
        background-position: 0 -321px;

        &.icon-flag-m {
            background-position: 0 -480px;
        }
    }

    &.BD {
        background-position: 0 -341px;

        &.icon-flag-m {
            background-position: 0 -510px;
        }
    }

    &.BE {
        background-position: 0 -361px;

        &.icon-flag-m {
            background-position: 0 -540px;
        }
    }

    &.BF {
        background-position: 0 -381px;

        &.icon-flag-m {
            background-position: 0 -570px;
        }
    }

    &.BG {
        background-position: 0 -401px;

        &.icon-flag-m {
            background-position: 0 -600px;
        }
    }

    &.BH {
        background-position: 0 -421px;

        &.icon-flag-m {
            background-position: 0 -630px;
        }
    }

    &.BI {
        background-position: 0 -441px;

        &.icon-flag-m {
            background-position: 0 -660px;
        }
    }

    &.BJ {
        background-position: 0 -461px;

        &.icon-flag-m {
            background-position: 0 -690px;
        }
    }

    &.BM {
        background-position: 0 -482px;

        &.icon-flag-m {
            background-position: 0 -720px;
        }
    }

    &.BN {
        background-position: 0 -502px;

        &.icon-flag-m {
            background-position: 0 -750px;
        }
    }

    &.BO {
        background-position: 0 -522px;

        &.icon-flag-m {
            background-position: 0 -780px;
        }
    }

    &.BR {
        background-position: 0 -542px;

        &.icon-flag-m {
            background-position: 0 -810px;
        }
    }

    &.BS {
        background-position: 0 -562px;

        &.icon-flag-m {
            background-position: 0 -840px;
        }
    }

    &.BT {
        background-position: 0 -582px;

        &.icon-flag-m {
            background-position: 0 -870px;
        }
    }

    &.BW {
        background-position: 0 -602px;

        &.icon-flag-m {
            background-position: 0 -900px;
        }
    }

    &.BY {
        background-position: 0 -622px;

        &.icon-flag-m {
            background-position: 0 -930px;
        }
    }

    &.BZ {
        background-position: 0 -642px;

        &.icon-flag-m {
            background-position: 0 -960px;
        }
    }

    &.CA {
        background-position: 0 -662px;

        &.icon-flag-m {
            background-position: 0 -990px;
        }
    }

    &.CAF {
        background-position: 0 -682px;

        &.icon-flag-m {
            background-position: 0 -1020px;
        }
    }

    &.CAS {
        background-position: 0 -703px;

        &.icon-flag-m {
            background-position: 0 -1050px;
        }
    }

    &.CD {
        background-position: 0 -723px;

        &.icon-flag-m {
            background-position: 0 -1080px;
        }
    }

    &.CEU {
        background-position: 0 -743px;

        &.icon-flag-m {
            background-position: 0 -1110px;
        }
    }

    &.CF {
        background-position: 0 -763px;

        &.icon-flag-m {
            background-position: 0 -1140px;
        }
    }

    &.CG {
        background-position: 0 -783px;

        &.icon-flag-m {
            background-position: 0 -1170px;
        }
    }

    &.CH {
        background-position: 0 -803px;

        &.icon-flag-m {
            background-position: 0 -1200px;
        }
    }

    &.CI {
        background-position: 0 -823px;

        &.icon-flag-m {
            background-position: 0 -1230px;
        }
    }

    &.CL {
        background-position: 0 -843px;

        &.icon-flag-m {
            background-position: 0 -1260px;
        }
    }

    &.CM {
        background-position: 0 -863px;

        &.icon-flag-m {
            background-position: 0 -1290px;
        }
    }

    &.CN {
        background-position: 0 -883px;

        &.icon-flag-m {
            background-position: 0 -1320px;
        }
    }

    &.CNA {
        background-position: 0 -904px;

        &.icon-flag-m {
            background-position: 0 -1350px;
        }
    }

    &.CO {
        background-position: 0 -924px;

        &.icon-flag-m {
            background-position: 0 -1380px;
        }
    }

    &.COC {
        background-position: 0 -944px;

        &.icon-flag-m {
            background-position: 0 -1410px;
        }
    }

    &.CR {
        background-position: 0 -964px;

        &.icon-flag-m {
            background-position: 0 -1440px;
        }
    }

    &.CSA {
        background-position: 0 -984px;

        &.icon-flag-m {
            background-position: 0 -1470px;
        }
    }

    &.CU {
        background-position: 0 -1004px;

        &.icon-flag-m {
            background-position: 0 -1500px;
        }
    }

    &.CV {
        background-position: 0 -1024px;

        &.icon-flag-m {
            background-position: 0 -1530px;
        }
    }

    &.CY {
        background-position: 0 -1044px;

        &.icon-flag-m {
            background-position: 0 -1560px;
        }
    }

    &.CZ {
        //background-position: 0 -1378px;
        background-position: 0 -1064px;

        &.icon-flag-m {
            background-position: 0 -1590px;
        }
    }

    &.DE {
        background-position: 0 -1084px;

        &.icon-flag-m {
            background-position: 0 -1620px;
        }
    }

    &.DJ {
        background-position: 0 -1104px;

        &.icon-flag-m {
            background-position: 0 -1650px;
        }
    }

    &.DK {
        background-position: 0 -1125px;

        &.icon-flag-m {
            background-position: 0 -1680px;
        }
    }

    &.DM {
        background-position: 0 -1145px;

        &.icon-flag-m {
            background-position: 0 -1710px;
        }
    }

    &.DO {
        background-position: 0 -1165px;

        &.icon-flag-m {
            background-position: 0 -1740px;
        }
    }

    &.DZ {
        background-position: 0 -1185px;

        &.icon-flag-m {
            background-position: 0 -1770px;
        }
    }

    &.EC {
        background-position: 0 -1205px;

        &.icon-flag-m {
            background-position: 0 -1800px;
        }
    }

    &.EE {
        background-position: 0 -1225px;

        &.icon-flag-m {
            background-position: 0 -1830px;
        }
    }

    &.EG {
        background-position: 0 -1245px;

        &.icon-flag-m {
            background-position: 0 -1860px;
        }
    }

    &.ER {
        background-position: 0 -1265px;

        &.icon-flag-m {
            background-position: 0 -1890px;
        }
    }

    &.ES {
        background-position: 0 -1285px;

        &.icon-flag-m {
            background-position: 0 -1920px;
        }
    }

    &.ET {
        background-position: 0 -1305px;

        &.icon-flag-m {
            background-position: 0 -1950px;
        }
    }

    &.EU {
        background-position: 0 -1325px;

        &.icon-flag-m {
            background-position: 0 -1980px;
        }
    }

    &.EH{
        background-position: 0 -3455px;
    }

    &.FI {
        background-position: 0 -1346px;

        &.icon-flag-m {
            background-position: 0 -2010px;
        }
    }

    &.FJ {
        background-position: 0 -1366px;

        &.icon-flag-m {
            background-position: 0 -2040px;
        }
    }

    &.FK {
        background-position: 0 -1386px;

        &.icon-flag-m {
            background-position: 0 -2070px;
        }
    }

    &.FM {
        background-position: 0 -1406px;

        &.icon-flag-m {
            background-position: 0 -2100px;
        }
    }

    &.FR {
        background-position: 0 -1426px;

        &.icon-flag-m {
            background-position: 0 -2130px;
        }
    }

    &.GA {
        background-position: 0 -1446px;

        &.icon-flag-m {
            background-position: 0 -2160px;
        }
    }

    &.EN {
        background-position: 0 -1466px;

        &.icon-flag-m {
            background-position: 0 -2190px;
        }
    }
    &.GB {
        background-position: 0 -1466px;

        &.icon-flag-m {
            background-position: 0 -2190px;
        }
    }

    &.GD {
        background-position: 0 -1506px;

        &.icon-flag-m {
            background-position: 0 -2220px;
        }
    }

    &.GE {
        background-position: 0 -1506px;

        &.icon-flag-m {
            background-position: 0 -2250px;
        }
    }

    &.GG {
        background-position: 0 -1546px;

        &.icon-flag-m {
            background-position: 0 -2280px;
        }
    }

    &.GH {
        background-position: 0 -1546px;

        &.icon-flag-m {
            background-position: 0 -2310px;
        }
    }

    &.GI {
        background-position: 0 -1587px;

        &.icon-flag-m {
            background-position: 0 -2340px;
        }
    }

    &.GM {
        background-position: 0 -1587px;

        &.icon-flag-m {
            background-position: 0 -2370px;
        }
    }

    &.GN {
        background-position: 0 -1607px;

        &.icon-flag-m {
            background-position: 0 -2400px;
        }
    }

    &.GQ {
        background-position: 0 -1627px;

        &.icon-flag-m {
            background-position: 0 -2430px;
        }
    }

    &.GR {
        background-position: 0 -1647px;

        &.icon-flag-m {
            background-position: 0 -2460px;
        }
    }

    &.GT {
        background-position: 0 -1667px;

        &.icon-flag-m {
            background-position: 0 -2490px;
        }
    }

    &.GW {
        background-position: 0 -1707px;

        &.icon-flag-m {
            background-position: 0 -2520px;
        }
    }

    &.GY {
        background-position: 0 -1727px;

        &.icon-flag-m {
            background-position: 0 -2550px;
        }
    }

    &.HK {
        background-position: 0 -1727px;

        &.icon-flag-m {
            background-position: 0 -2580px;
        }
    }

    &.HN {
        background-position: 0 -1747px;

        &.icon-flag-m {
            background-position: 0 -2610px;
        }
    }

    &.HR {
        background-position: 0 -1767px;

        &.icon-flag-m {
            background-position: 0 -2640px;
        }
    }

    &.HT {
        background-position: 0 -1788px;

        &.icon-flag-m {
            background-position: 0 -2670px;
        }
    }

    &.HU {
        background-position: 0 -1808px;

        &.icon-flag-m {
            background-position: 0 -2700px;
        }
    }

    &.ID {
        background-position: 0 -1828px;

        &.icon-flag-m {
            background-position: 0 -2730px;
        }
    }

    &.IE {
        background-position: 0 -1868px;

        &.icon-flag-m {
            background-position: 0 -2760px;
        }
    }

    &.IL {
        background-position: 0 -1868px;

        &.icon-flag-m {
            background-position: 0 -2790px;
        }
    }

    &.IM {
        background-position: 0 -1908px;

        &.icon-flag-m {
            background-position: 0 -2820px;
        }
    }

    &.IN {
        background-position: 0 -1908px;

        &.icon-flag-m {
            background-position: 0 -2850px;
        }
    }

    &.IQ {
        background-position: 0 -1928px;

        &.icon-flag-m {
            background-position: 0 -2880px;
        }
    }

    &.IR {
        background-position: 0 -1948px;

        &.icon-flag-m {
            background-position: 0 -2910px;
        }
    }

    &.IS {
        background-position: 0 -1968px;

        &.icon-flag-m {
            background-position: 0 -2940px;
        }
    }

    &.IT {
        background-position: 0 -1989px;

        &.icon-flag-m {
            background-position: 0 -2970px;
        }
    }

    &.JE {
        background-position: 0 -2029px;

        &.icon-flag-m {
            background-position: 0 -3000px;
        }
    }

    &.JM {
        background-position: 0 -2029px;

        &.icon-flag-m {
            background-position: 0 -3030px;
        }
    }

    &.JO {
        background-position: 0 -2049px;

        &.icon-flag-m {
            background-position: 0 -3060px;
        }
    }

    &.JP {
        background-position: 0 -2069px;

        &.icon-flag-m {
            background-position: 0 -2109px;
        }
    }

    &.KE {
        background-position: 0 -2089px;

        &.icon-flag-m {
            background-position: 0 -3120px;
        }
    }

    &.KG {
        background-position: 0 -2109px;

        &.icon-flag-m {
            background-position: 0 -3150px;
        }
    }

    &.KH {
        background-position: 0 -2129px;

        &.icon-flag-m {
            background-position: 0 -3180px;
        }
    }

    &.KM {
        background-position: 0 -2189px;

        &.icon-flag-m {
            background-position: 0 -3210px;
        }
    }

    &.KN {
        background-position: 0 -2210px;

        &.icon-flag-m {
            background-position: 0 -3240px;
        }
    }

    &.KP {
        background-position: 0 -2189px;

        &.icon-flag-m {
            background-position: 0 -3270px;
        }
    }

    &.KR {
        background-position: 0 -2210px;

        &.icon-flag-m {
            background-position: 0 -3300px;
        }
    }

    &.KW {
        background-position: 0 -2230px;

        &.icon-flag-m {
            background-position: 0 -3330px;
        }
    }

    &.KY {
        background-position: 0 -2290px;

        &.icon-flag-m {
            background-position: 0 -3360px;
        }
    }

    &.KZ {
        background-position: 0 -2270px;

        &.icon-flag-m {
            background-position: 0 -3390px;
        }
    }

    &.LA {
        background-position: 0 -2290px;

        &.icon-flag-m {
            background-position: 0 -3420px;
        }
    }

    &.LB {
        background-position: 0 -2310px;

        &.icon-flag-m {
            background-position: 0 -3450px;
        }
    }

    &.LC {
        background-position: 0 -2370px;

        &.icon-flag-m {
            background-position: 0 -3480px;
        }
    }

    &.LI {
        background-position: 0 -2350px;

        &.icon-flag-m {
            background-position: 0 -3510px;
        }
    }

    &.LK {
        background-position: 0 -2370px;

        &.icon-flag-m {
            background-position: 0 -3540px;
        }
    }

    &.LR {
        background-position: 0 -2390px;

        &.icon-flag-m {
            background-position: 0 -3570px;
        }
    }

    &.LS {
        background-position: 0 -2410px;

        &.icon-flag-m {
            background-position: 0 -3600px;
        }
    }

    &.LT {
        background-position: 0 -2431px;

        &.icon-flag-m {
            background-position: 0 -3630px;
        }
    }

    &.LU {
        background-position: 0 -2451px;

        &.icon-flag-m {
            background-position: 0 -3660px;
        }
    }

    &.LV {
        background-position: 0 -2471px;

        &.icon-flag-m {
            background-position: 0 -3690px;
        }
    }

    &.LY {
        background-position: 0 -2491px;

        &.icon-flag-m {
            background-position: 0 -3720px;
        }
    }

    &.MA {
        background-position: 0 -2511px;

        &.icon-flag-m {
            background-position: 0 -3750px;
        }
    }

    &.MC {
        background-position: 0 -2531px;

        &.icon-flag-m {
            background-position: 0 -3780px;
        }
    }

    &.MD {
        background-position: 0 -2551px;

        &.icon-flag-m {
            background-position: 0 -3810px;
        }
    }

    &.ME {
        background-position: 0 -2571px;

        &.icon-flag-m {
            background-position: 0 -3840px;
        }
    }

    &.MG {
        background-position: 0 -2591px;

        &.icon-flag-m {
            background-position: 0 -3870px;
        }
    }

    &.MK {
        background-position: 0 -2611px;

        &.icon-flag-m {
            background-position: 0 -3900px;
        }
    }

    &.ML {
        background-position: 0 -2631px;

        &.icon-flag-m {
            background-position: 0 -3930px;
        }
    }

    &.MM {
        background-position: 0 -2652px;

        &.icon-flag-m {
            background-position: 0 -3960px;
        }
    }

    &.MN {
        background-position: 0 -2672px;

        &.icon-flag-m {
            background-position: 0 -3990px;
        }
    }

    &.MO {
        background-position: 0 -2732px;

        &.icon-flag-m {
            background-position: 0 -4020px;
        }
    }

    &.MR {
        background-position: 0 -2712px;

        &.icon-flag-m {
            background-position: 0 -4050px;
        }
    }

    &.MS {
        background-position: 0 -2772px;

        &.icon-flag-m {
            background-position: 0 -4080px;
        }
    }

    &.MT {
        background-position: 0 -2752px;

        &.icon-flag-m {
            background-position: 0 -4110px;
        }
    }

    &.MU {
        background-position: 0 -2772px;

        &.icon-flag-m {
            background-position: 0 -4140px;
        }
    }

    &.MV {
        background-position: 0 -2792px;

        &.icon-flag-m {
            background-position: 0 -4170px;
        }
    }

    &.MW {
        background-position: 0 -2852px;

        &.icon-flag-m {
            background-position: 0 -4200px;
        }
    }

    &.MX {
        background-position: 0 -2832px;

        &.icon-flag-m {
            background-position: 0 -4230px;
        }
    }

    &.MY {
        background-position: 0 -2852px;

        &.icon-flag-m {
            background-position: 0 -4260px;
        }
    }

    &.MZ {
        background-position: 0 -2873px;

        &.icon-flag-m {
            background-position: 0 -4290px;
        }
    }

    &.NA {
        background-position: 0 -2893px;

        &.icon-flag-m {
            background-position: 0 -4320px;
        }
    }

    &.NE {
        background-position: 0 -2913px;

        &.icon-flag-m {
            background-position: 0 -4350px;
        }
    }

    &.NG {
        background-position: 0 -2933px;

        &.icon-flag-m {
            background-position: 0 -4380px;
        }
    }

    &.NI {
        background-position: 0 -2953px;

        &.icon-flag-m {
            background-position: 0 -4410px;
        }
    }

    &.NL {
        background-position: 0 -2973px;

        &.icon-flag-m {
            background-position: 0 -4440px;
        }
    }

    &.NO {
        background-position: 0 -2993px;

        &.icon-flag-m {
            background-position: 0 -4470px;
        }
    }

    &.NP {
        background-position: 0 -3013px;

        &.icon-flag-m {
            background-position: 0 -4500px;
        }
    }

    &.NZ {
        background-position: 0 -3033px;

        &.icon-flag-m {
            background-position: 0 -4530px;
        }
    }

    &.OM {
        background-position: 0 -3053px;

        &.icon-flag-m {
            background-position: 0 -4560px;
        }
    }

    &.PA {
        background-position: 0 -3074px;

        &.icon-flag-m {
            background-position: 0 -4590px;
        }
    }

    &.PE {
        background-position: 0 -3094px;

        &.icon-flag-m {
            background-position: 0 -4620px;
        }
    }

    &.PF {
        background-position: 0 -3154px;

        &.icon-flag-m {
            background-position: 0 -4650px;
        }
    }

    &.PG {
        background-position: 0 -3174px;

        &.icon-flag-m {
            background-position: 0 -4680px;
        }
    }

    &.PH {
        background-position: 0 -3154px;

        &.icon-flag-m {
            background-position: 0 -4710px;
        }
    }

    &.PS{
        background-position: 0 -2049px;
    }

    &.PK {
        background-position: 0 -3174px;

        &.icon-flag-m {
            background-position: 0 -4740px;
        }
    }

    &.PL {
        background-position: 0 -3194px;

        &.icon-flag-m {
            background-position: 0 -4770px;
        }
    }

    &.PR {
        background-position: 0 -3214px;

        &.icon-flag-m {
            background-position: 0 -4800px;
        }
    }

    &.PT {
        background-position: 0 -3234px;

        &.icon-flag-m {
            background-position: 0 -4830px;
        }
    }

    &.PW {
        background-position: 0 -3295px;

        &.icon-flag-m {
            background-position: 0 -4860px;
        }
    }

    &.PY {
        background-position: 0 -3274px;

        &.icon-flag-m {
            background-position: 0 -4890px;
        }
    }

    &.QA {
        background-position: 0 -3295px;

        &.icon-flag-m {
            background-position: 0 -4920px;
        }
    }

    &.RO {
        background-position: 0 -3315px;

        &.icon-flag-m {
            background-position: 0 -4950px;
        }
    }

    &.RS {
        background-position: 0 -3335px;

        &.icon-flag-m {
            background-position: 0 -4980px;
        }
    }

    &.RU {
        background-position: 0 -3355px;

        &.icon-flag-m {
            background-position: 0 -5010px;
        }
    }

    &.RW {
        background-position: 0 -3375px;

        &.icon-flag-m {
            background-position: 0 -5040px;
        }
    }

    &.SA {
        background-position: 0 -3395px;

        &.icon-flag-m {
            background-position: 0 -5070px;
        }
    }

    &.SB {
        background-position: 0 -3455px;

        &.icon-flag-m {
            background-position: 0 -5100px;
        }
    }

    &.SC {
        background-position: 0 -3435px;

        &.icon-flag-m {
            background-position: 0 -5130px;
        }
    }

    &.SD {
        background-position: 0 -3455px;

        &.icon-flag-m {
            background-position: 0 -5160px;
        }
    }

    &.SE {
        background-position: 0 -3475px;

        &.icon-flag-m {
            background-position: 0 -5190px;
        }
    }

    &.SG {
        background-position: 0 -3495px;

        &.icon-flag-m {
            background-position: 0 -5220px;
        }
    }

    &.SH {
        background-position: 0 -3556px;

        &.icon-flag-m {
            background-position: 0 -5250px;
        }
    }

    &.SI {
        background-position: 0 -3536px;

        &.icon-flag-m {
            background-position: 0 -5280px;
        }
    }

    &.SK {
        background-position: 0 -3556px;

        &.icon-flag-m {
            background-position: 0 -5310px;
        }
    }

    &.SL {
        background-position: 0 -4098px;

        &.icon-flag-m {
            background-position: 0 -5340px;
        }
    }

    &.SM {
        background-position: 0 -3596px;

        &.icon-flag-m {
            background-position: 0 -5370px;
        }
    }

    &.SN {
        background-position: 0 -3616px;

        &.icon-flag-m {
            background-position: 0 -5400px;
        }
    }

    &.SO {
        background-position: 0 -3636px;

        &.icon-flag-m {
            background-position: 0 -5430px;
        }
    }

    &.SR {
        background-position: 0 -3656px;

        &.icon-flag-m {
            background-position: 0 -5460px;
        }
    }

    &.ST {
        background-position: 0 -3716px;

        &.icon-flag-m {
            background-position: 0 -5490px;
        }
    }

    &.SV {
        background-position: 0 -3737px;

        &.icon-flag-m {
            background-position: 0 -5520px;
        }
    }

    &.SY {
        background-position: 0 -3716px;

        &.icon-flag-m {
            background-position: 0 -5550px;
        }
    }

    &.SZ {
        background-position: 0 -3777px;

        &.icon-flag-m {
            background-position: 0 -5580px;
        }
    }

    &.TC {
        background-position: 0 -3797px;

        &.icon-flag-m {
            background-position: 0 -5610px;
        }
    }

    &.TD {
        background-position: 0 -3777px;

        &.icon-flag-m {
            background-position: 0 -5640px;
        }
    }

    &.TG {
        background-position: 0 -3797px;

        &.icon-flag-m {
            background-position: 0 -5670px;
        }
    }

    &.TH {
        background-position: 0 -3917px;

        &.icon-flag-m {
            background-position: 0 -5700px;
        }
    }

    &.TJ {
        background-position: 0 -3837px;

        &.icon-flag-m {
            background-position: 0 -5730px;
        }
    }

    &.TL {
        background-position: 0 -3897px;

        &.icon-flag-m {
            background-position: 0 -5760px;
        }
    }

    &.TM {
        background-position: 0 -3877px;

        &.icon-flag-m {
            background-position: 0 -5790px;
        }
    }

    &.TN {
        background-position: 0 -3897px;

        &.icon-flag-m {
            background-position: 0 -5820px;
        }
    }

    &.TO {
        background-position: 0 -3958px;

        &.icon-flag-m {
            background-position: 0 -5850px;
        }
    }

    &.TR {
        background-position: 0 -3937px;

        &.icon-flag-m {
            background-position: 0 -5880px;
        }
    }

    &.TT {
        background-position: 0 -3998px;

        &.icon-flag-m {
            background-position: 0 -5910px;
        }
    }

    &.TW {
        background-position: 0 -3978px;

        &.icon-flag-m {
            background-position: 0 -5940px;
        }
    }

    &.TZ {
        background-position: 0 -3998px;

        &.icon-flag-m {
            background-position: 0 -5970px;
        }
    }

    &.UA {
        background-position: 0 -4018px;

        &.icon-flag-m {
            background-position: 0 -6000px;
        }
    }

    &.UG {
        background-position: 0 -4038px;

        &.icon-flag-m {
            background-position: 0 -6030px;
        }
    }

    &.US {
        background-position: 0 -4058px;

        &.icon-flag-m {
            background-position: 0 -6060px;
        }
    }

    &.UY {
        background-position: 0 -4078px;

        &.icon-flag-m {
            background-position: 0 -6090px;
        }
    }

    &.UZ {
        background-position: 0 -4098px;

        &.icon-flag-m {
            background-position: 0 -6120px;
        }
    }

    &.VC {
        background-position: 0 -4159px;

        &.icon-flag-m {
            background-position: 0 -6150px;
        }
    }

    &.VE {
        background-position: 0 -4138px;

        &.icon-flag-m {
            background-position: 0 -6180px;
        }
    }

    &.VG {
        background-position: 0 -4199px;

        &.icon-flag-m {
            background-position: 0 -6210px;
        }
    }

    &.VN {
        background-position: 0 -4179px;

        &.icon-flag-m {
            background-position: 0 -6240px;
        }
    }

    &.VU {
        background-position: 0 -4239x;

        &.icon-flag-m {
            background-position: 0 -6270px;
        }
    }

    &.WS {
        background-position: 0 -4259px;

        &.icon-flag-m {
            background-position: 0 -6300px;
        }
    }

    &.WW {
        background-position: 0 -4279px;

        &.icon-flag-m {
            background-position: 0 -6330px;
        }
    }

    &.YE {
        background-position: 0 -4259px;

        &.icon-flag-m {
            background-position: 0 -6360px;
        }
    }

    &.ZA {
        background-position: 0 -4279px;

        &.icon-flag-m {
            background-position: 0 -6390px;
        }
    }

    &.ZM {
        background-position: 0 -4299px;

        &.icon-flag-m {
            background-position: 0 -6420px;
        }
    }

    &.ZW {
        background-position: 0 -4319px;

        &.icon-flag-m {
            background-position: 0 -6450px;
        }
    }
}