.upload__zone {
  position: relative;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 2px dashed var(--green-600);
  width: 100%;
  padding: 10px 24px;
  min-height: 200px;
  text-align: center;
  cursor: pointer;
  z-index: 1;

  &__video{
    max-width: 280px;
  }
  .status {

    &.create-page__upload-video {
      width: 340px;
      height: 190px;
    }

    .loader-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    
  }

  &:hover {
    border-color: var(--green-main);
    background: var(--green-100);

    input {
      pointer-events: all;
      cursor: pointer;
    }
  }
  &.drag {
    border-color: var(--green-main);
  }

  input[type="file"] {
    pointer-events: none;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    padding: 20px;
    // color: var(--green-main);
  }
}
.upload__zone.disabled{
  opacity: 0.3;
  &:hover{
    border-color: var(--green-600);
    background: transparent;
  }
}
.ero-status {
  background-color: var(--color-700-reverse);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  // border-radius: 0 0 12px 12px;
}
.inputDisplay {
  display: none;
}
