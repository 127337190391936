.icon {
  &, &-square{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    border-radius: 4px;
    gap: 4px;
    height: max-content;
    font-size: 18px;
    &:before {
      font-family: "lady4love" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      -webkit-mask-size: 100%;
      mask-size: 100%;
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &._green {
    color: var(--green-main);
  }

  &._red {
    color: var(--red-main);
  }

  &-6xl {
    border-radius: 12px;
    font-size: 64px;
  }

  &-5xl {
    border-radius: 12px;
    font-size: 48px;
  }

  &-4xl {
    border-radius: 12px;
    font-size: 40px;
  }

  &-xxxl {
    border-radius: 12px;
    font-size: 36px;
  }

  &-xxl {
    font-size: 24px;
  }
  
  &-l {
    font-size: 24px;
  }

  &-s {
    font-size: 16px;
  }
 

  &-2xs {
    font-size: 8px;
  }

  // квадратные
  &-square{
    background-color: var(--color-300);
    aspect-ratio: 1/1;
    &-xxxl {
      width: 40px;
      min-width: 40px;
      font-size: 36px;
    }

    &-xxl {
      width: 36px;
      min-width: 36px;
      font-size: 32px;
    }

    &-xl {
      width: 32px;
      min-width: 32px;
      font-size: 28px;
    }

    &-l {
      width: 28px;
      min-width: 28px;
      font-size: 24px;
    }

    &-m {
      width: 24px;
      min-width: 24px;
      font-size: 20px;
    }

    &-s {
      width: 20px;
      min-width: 20px;
      font-size: 16px;
    }

    &-xs {
      width: 16px;
      min-width: 16px;
      font-size: 12px;
    }

    &-2xs {
      width: 12px;
      min-width: 12px;
      font-size: 8px;
    }
  }

  // Иконки

  &--diamond {
    content: url("/assets/img/icons/diamond.svg");
    filter: drop-shadow(0px 0px 8px #00f2ff);
  }

  &--favorite-fill:before {
    color: var(--green-main);
  }
}

.currency{
  &--erocoin:after{
    line-height: 1em;
    font-family: "lady4love" !important;
    content: "\e020" !important;
  }

  &--euro:after {
    content: "€";
    margin-left: 4px;
  }

  &--czk:after {
    content: "Kč";
    margin-left: 4px;
  }
}

.icon--bank:before {
  content: "\e01c";
}

.icon--body-fill:before {
  content: "\63";
}
.icon--burger:before {
  content: "\64";
}
.icon--calendar:before {
  content: "\65";
}
.icon--call:before {
  content: "\66";
}
.icon--call-outline:before {
  content: "\68";
}
.icon--check:before {
  content: "\69";
}
.icon--chevrone-down:before {
  content: "\6a";
}
.icon--chevrone-left:before {
  content: "\6b";
}
.icon--chevrone-right:before {
  content: "\6c";
}
.icon--chevrone-up:before {
  content: "\6d";
}
.icon--health-fill:before {
  content: "\6e";
}
.icon--comment:before {
  content: "\70";
}
.icon--dark:before {
  content: "\72";
}
.icon--edit:before {
  content: "\74";
}
.icon--incognito:before {
  content: "\75";
}
.icon--edit-2:before {
  content: "\76";
}
.icon--light:before {
  content: "\77";
}
.icon--video-play:before {
  content: "\78";
}
.icon--video-fill:before {
  content: "\79";
}
.icon--verify-fill:before {
  content: "\7a";
}
.icon--verify:before {
  content: "\41";
}
.icon--upload:before {
  content: "\42";
}
.icon--escort:before {
  content: "\43";
}
.icon--eye:before {
  content: "\73";
}
.icon--eye-off:before {
  content: "\45";
}
.icon--favorite:before {
  content: "\44";
}
.icon--favorite-fill:before {
  content: "\46";
}
.icon--film:before {
  content: "\47";
}
.icon--home-main:before {
  content: "\71";
}
.icon--image:before {
  content: "\48";
}
.icon--location:before {
  content: "\49";
}
.icon--person:before {
  content: "\4a";
}
.icon--erocoin-bold:before {
  content: "\4c";
}
.icon--erocoin-bold-1:before {
  content: "\4d";
}
.icon--erocoin-four:before {
  content: "\4e";
}
.icon--erocoin-leg:before {
  content: "\4f";
}
.icon--filter:before {
  content: "\50";
}
.icon--filter2:before {
  content: "\51";
}
.icon--girlfriend:before {
  content: "\52";
}
.icon--fix:before {
  content: "\53";
}
.icon--ing-yang-1:before {
  content: "\54";
}
.icon--ing-yang-2:before {
  content: "\55";
}
.icon--logout:before {
  content: "\56";
}
.icon--map:before {
  content: "\57";
}
.icon--minus:before {
  content: "\58";
}
.icon--more:before {
  content: "\59";
}
.icon--navigation:before {
  content: "\5a";
}
.icon--notification:before {
  content: "\30";
}
.icon--notify:before {
  content: "\31";
}
.icon--pants:before {
  content: "\32";
}
.icon--payment:before {
  content: "\33";
}
.icon--photo:before {
  content: "\34";
}
.icon--plus:before {
  content: "\35";
}
.icon--plus-1:before {
  content: "\36";
}
.icon--profile-add:before {
  content: "\37";
}
.icon--profile-card:before {
  content: "\38";
}
.icon--promo:before {
  content: "\39";
}
.icon--search:before {
  content: "\21";
}
.icon--sort:before {
  content: "\22";
}
.icon--star-fill:before {
  content: "\23";
}
.icon--stat:before {
  content: "\24";
}
.icon--statistic:before {
  content: "\25";
}
.icon--status:before {
  content: "\26";
}
.icon--telegram:before {
  content: "\27";
}
.icon--travel:before {
  //content: "\4b";
  content: "\e021";
}
.icon--web-cam:before {
  content: "\28";
}
.icon--call-check:before {
  content: "\2b";
}
.icon--catalog:before {
  content: "\2c";
}
.icon--diploma-verified:before {
  content: "\2e";
}
.icon--filter-1:before {
  content: "\2f";
}
.icon--gender-couple:before {
  content: "\3a";
}
.icon--gender-female:before {
  content: "\3b";
}
.icon--gender-male:before {
  content: "\3c";
}
.icon--gender-trans:before {
  content: "\3d";
}
.icon--globe:before {
  content: "\3e";
}
.icon--map-pin:before {
  content: "\3f";
}
.icon--play:before {
  content: "\5e";
}
.icon--whatsapp:before {
  content: "\5f";
}
.icon--party:before {
  content: "\60";
}
.icon--volume:before {
  content: "\7b";
}
.icon--volume-x:before {
  content: "\7c";
}
.icon--payed:before {
  content: "\67";
}
.icon--warning:before {
  content: "\6f";
}
.icon--wallet-money:before {
  content: "\40";
}
.icon--user-id:before {
  content: "\5b";
}
.icon--user-circle:before {
  content: "\5d";
}
.icon--undo-left:before {
  content: "\7d";
}
.icon--triangle:before {
  content: "\5c";
}
.icon--trash:before {
  content: "\e000";
}
.icon--time-plan:before {
  content: "\e001";
}
.icon--star:before {
  content: "\e002";
}
.icon--small-view:before {
  content: "\e003";
}
.icon--send:before {
  content: "\e004";
}
.icon--secure:before {
  content: "\e005";
}
.icon--save:before {
  content: "\e006";
}
.icon--mail:before {
  content: "\e008";
}
.icon--longer-view:before {
  content: "\e009";
}
.icon--instruction:before {
  content: "\e00a";
}
.icon--info:before {
  content: "\e00b";
}
.icon--import:before {
  content: "\e00c";
}
.icon--geo:before {
  content: "\e00d";
}
.icon--euro:before {
  content: "\e00e";
}
.icon--error:before {
  content: "\e00f";
}
.icon--copy:before {
  content: "\e010";
}
.icon--close:before {
  content: "\e011";
}
.icon--change:before {
  content: "\e012";
}
.icon--cash-up:before {
  content: "\e013";
}
.icon--cash-down:before {
  content: "\e014";
}
.icon--camera:before {
  content: "\e015";
}
.icon--arrow-up-right:before {
  content: "\e016";
}
.icon--user:before {
  content: "\7e";
}
.icon--operator:before {
  content: "\e017";
}
.icon--instruction-cont:before {
  content: "\e018";
}
.icon--pay-content:before {
  content: "\e007";
}
.icon--casting:before {
  content: "\e019";
}
.icon--erocoin:before {
  content: "\e01a";
}
.icon--arrow-left:before {
  content: "\2a";
}
.icon--arrow-right:before {
  content: "\e01b";
}
.icon--arrow3:before {
  content: "\e01b";
}
.icon--card:before {
  content: "\e01d";
}
.icon--novideo:before {
  content: "\43b";
}
.icon--video:before {
  content: "\2d";
}
.icon--bitcoin:before {
  content: "\e01e";
}
.icon--time:before {
  content: "\e01f";
}
.icon--18-popup:before {
  content: "\29";
}
.icon--body:before {
  content: "\61";
}
.icon--baggage:before {
  content: "\e021";
}
.icon--body2:before {
  content: "\62";
}
.icon--currency-erocoin:before {
  content: "\e020";
}
.icon--paypal:before {
  content: "\e022";
}