@import "./src/styles/variables.scss";

.profiles-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    @media (max-width: $mobile) {
      gap: 12px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      margin: 0;
    }
    button.button--green{
      margin-left: 12px;
      margin-right: auto;
      @media (max-width: $mobile) {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  &.view {
    // display: grid;
    // grid-template-columns: repeat(6, max-content);
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 1199px) {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding-left: 0px;
      div.search{
        max-width: 50%;
        min-width: 30%;
      }
    }

    @media (max-width: 560px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      padding-left: 0px;
      & div.checkbox.ml-auto {
        margin-left: 0%;
      }
    }
    @media (max-width: 400px) {
      div.search{
        max-width: 69%;
        min-width: 60%;
      }
      div.dropdown{
        margin-left: auto;
      }
    }
  }

  &.view__btn {
    @media (max-width: 1199px) {
      display: none;
    }
  }

  &__grid-ads {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(2, 1fr);

    &.small {
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 1370.98px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 1140.98px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &__filter {
    position: relative;

    &__label {
      position: relative;
      cursor: text;
      border-radius: 4px;
      padding: 4px 4px 4px 12px;
      min-height: 40px;
      background-color: var(--black-light);
      transition: background-color var(--switch-theme-time);
      display: flex;
      align-items: center;

      &:hover,
      &:focus-within {
        background-color: var(--color-400);
      }
    }

    &__input {
      width: 100%;
      min-height: 0;
      border-radius: 0;
      background-color: transparent;
      outline: none;
      color: var(--color-600);

      &::placeholder {
        color: var(--color-600);
        transition: color var(--switch-theme-time);
      }

      @media (max-width: $mobile) {
        font-size: 12px;
        padding: 0 40px 0 0;
      }
    }

    &__close {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      @media (max-width: $mobile) {
        border-radius: 4px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
