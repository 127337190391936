.progress-bar {
  //   background-color: var(--green-main);
  width: 40px;
  height: 40px;
}

.CircularProgressbar-text {
  font-size: 24px !important;
  dominant-baseline: central !important;
}
