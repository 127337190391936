@import './src/styles/variables';

.banner{
    position: relative;
    min-height: 384px;
    @media(max-width: $mobile) {
        min-height: 264px;
    }

    .model__review{
        display: flex !important;
        width: max-content;
    }

    &__img {
        left: 16px;
        right: 16px;
        top: 0;
        bottom: 0;
        position: absolute;
        border-radius: 24px;
        overflow: hidden;
        background: url("/assets/img/agency/banner-cover.webp");
        background-repeat: no-repeat;
        background-position: center top;
        @media(max-width: $mobileSmall){
            left: 6px;
            right: 6px;
            background: url("/assets/img/agency/banner-cover-mob.webp");
        }
    }
    &__daytime{
        display: flex;
        gap: 6px;
        align-items: center;
    }
}