@import "./src/styles/variables.scss";

.statistic-table {
    width: 100%;
    overflow: auto;
    border: 1px solid var(--color-300);
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    &__wrapper{
        
        height: 100%;
        max-width: 1440px;
        @media (max-width: $tablet) {
            min-width: max-content;
        }
    }

    &__rows{
        display: flex;
        flex-direction: column-reverse;
    }

    &__header, &__row {
        display: grid;
        grid-template-columns: 104px repeat(8, 1fr);
        align-items: center;
        gap: 1px;
        div {
            // padding added
            width: 100%;
            
            padding: 10px 4px;
            text-align: center;
            padding: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--color-main);

            &:nth-child(1) {
                justify-content: flex-start;
            }
        }
        .bg-main{
            background-color: var(--bg-main);
        }
        .gray-cell{
            background-color: var(--color-100);
        }
        .dark-cell{
            background-color: var(--color-200);
        }
        .darkest-cell{
            background-color: var(--color-300);
        }
        .red-cell{
            background-color: var(--red-500);
        }
        .green-cell{
            background-color: var(--green-500);
        }
        .sticky-cell{
            position: sticky;
            left: 0%;
        }
        .promoted-cell{
            background-color: var(--green-700)
        }
        .elite-cell{
            background-color: var(--gold-700)
        }
        .promoted-cell.elite-cell {
            background: linear-gradient(
                            to right,
                            var(--green-700) 50%,
                            var(--gold-700) 50%
            );
        }
    }

    &__header{
        width: 100%;
    }

    &__row{
        margin-top: 1px;
    }
    &__row:hover .bg-main{
       background-color: var(--gold-400);
    }
    &__row:hover{
        background-color: var(--gold-400);}
    

    .bold-row{
        font-weight: 600;
    }
    .with_positions {
        grid-template-columns: 104px repeat(9, 1fr);
    }

    &__options{
        display: flex;
        gap: 16px;
        align-items: center;
        margin-top: 16px;
        span{
            position: relative;
            color: var(--color-main);
            padding-left: 20px;
            &::before{
                content: '';
                position: absolute;
                width: 12px;
                height: 4px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    &__option{
        &--elite{
            &::before{
                background: linear-gradient(
                                180deg,
                                rgba(186, 161, 99, 1) 30%,
                                rgba(186, 161, 99, 1) 94%
                );
            }
        }
        &--promo{
            &::before{
                background-color: var(--green-main);
            }
        }
    }

    
}
// .statistic-table2{
//     display: grid;
//     overflow-x: auto;
//     overflow-y: visible;
//     grid-template-columns: repeat(10, 1fr);
//     border-radius: 16px;
//     gap: 1px;
//     border: 1px solid var(--color-300);

//     div.column{
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         gap: 1px;
//         div.cell{
//             text-align: center;
//             height: max-content;
//             padding: 8px;
//             width: 100%;
//             min-height: 40px;
//             max-height: 50px;
//             color: var(--color-main);
//         }
//         div.cell.bold{
//             font-weight: bold;
//         }
//         div.cell:not(:last-child){
            
//         }

//         div.cell.head-cell{
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             height: 57px;
//         }
//     }
    
//     div.column:first-child{
//        position: sticky;
//        left: 0;
//     }

//     div.column:not(:last-child){
        
//     }

//     .date-cell{
//         background-color: var(--bg-main);
//     }
//     .date-cell.bold{
//         font-weight: bold;
//     }

//     .profileshows-cell,.position-cell{
//         background-color: var(--color-100);
//     }
//     .viewcontact-cell,.viewprofile-cell{
//         background-color: var(--color-200);
//     }
//     .g-preorders-cell,
//     .minofvideo-cell,
//     .erocontentaccess-cell{
//         background-color: var(--color-300);
//     }
//     .expences-cell{
//         background-color: var(--red-500);
//     }
//     .income-cell{
//         background-color: var(--green-500);
//     }
//     .date-cell{
//         min-width: 95px;
//         position: sticky;
//         left: 0%;
//     }
//     .promoted-cell{
//         background-color: var(--green-700)
//     }
//     .elite-cell{
//         background-color: var(--gold-700)
//     }
//     .promoted-cell.elite-cell {
//         background: linear-gradient(
//                         to right,
//                         var(--green-700) 50%,
//                         var(--gold-700) 50%
//         );
//     }

//     .hover-row{
//         background-color: var(--color-400);
//     }
//     .date-cell.hover-row{
//         background-color: var(--black-light);
//     }
//     .promoted-cell.elite-cell.hover-row{
//         background: linear-gradient(
//             to right,
//             var(--green-300) 50%,
//             var(--gold-300) 50%)
//     }
    
// }


.statistic-table3{
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--color-300);
    gap: 0;
    overflow-x: auto;
    margin-bottom: 20px;
    font-size: 13px;

    &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: var(--color-200);
      }
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: var(--color-400);
      }

    @media (max-width: $mobile) {
        font-size: 12px;
    }
    
    table{
        width: 100%;
        border-collapse: collapse;
    }

    th{
        font-weight: 600;
        color: var(--color-main);
    }

    
    
    th, td {
        padding: 8px;
        text-align: center;
        border: 1px solid var(--color-300);
        color: var(--color-main);
        
        &.col-1{
            background-color: var(--color-200);
        }
        &.col-2{
            background-color: var(--color-300);
        }
        &.col-3{
            background-color: var(--color-400);
        }
        &.income{
            background-color: var(--green-500);
        }
        &.expense{
            background-color: var(--red-500);
        }
       
    }
    .bold{
        font-weight: 600;
        background: var(--color-400);
    }
    .bold:has(.sticky) .sticky {
        font-weight: 600;
        background: var(--black-light);
    }
    
    .sticky{
        
        position: sticky;
        left: 0;
        background: var(--bg-main);
        z-index: 2;
    }
    .promoted{
        background: var(--green-700);
    }
    .elite{
        background: var(--gold-700);
    }
    .promoted.elite{
        background: linear-gradient(
            to right,
            var(--green-700) 50%,
            var(--gold-700) 50%
        );;
    }
    
    tr:hover{
        cursor: pointer;
        background: var(--color-400);
        .sticky{
            background: var(--black-light);
        }
        .promoted{
            background: var(--green-accent);
        }
        .elite{
            background:  var(--gold-second);
        }
        .promoted.elite{
            background: linear-gradient(
                to right,
                var(--green-accent) 50%,
                var(--gold-second) 50%
            );;
        }
    }
    tr:has(th){
        background-color: var(--bg-main);
    }
    tr:has(th):hover{
        background: none;
    }
    .bold:has(.tilda) .tilda::before{
        content: '~';
    }
    
}