.pagination{
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    &__dots, &__num{
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__num{
        text-decoration: none;
        border-radius: 4px;
        border: 1px solid var(--color-400);
        &._active{
            border-color: var(--green-main);
        }
    }

    &__arrow{
        height: 24px;
    }
}