.ui-kit{
    &__icons{
        display: grid;

        grid-template-columns: repeat(6, 1fr);
        gap: 4px;
        background-color: var(--color-400);
        margin-bottom: 80px;
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            gap: 4px;
            padding: 4px;
            color: var(--color-main);
            border: 1px solid var(--color-main);
            i{
                font-size: 24px;
            }
            img{
                width: 24px;
                height: 24px;
            }
        }
    }
}