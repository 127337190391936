@import 'src/styles/variables';

.accordion {
  position: relative;
  border-bottom: 1px solid var(--color-300);

  &-button {
    width: 100%;
    padding: 16px 20px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    background-color: var(--color-200);
    transition: background-color var(--switch-theme-time);
    @media(max-width: $mobile) {
      font-size: 14px;
    }

    &:hover, &:focus-within {
      background-color: var(--color-400);
    }

    i {
      transform: rotate(0deg);
      transition: all 0.2s ease;

    }

    &._active {
      i {
        transform: rotate(180deg);
      }
    }
  }

  &__reset {
    position: absolute;
    right: 64px;
    top: 18px;
    color: var(--color-600);

    &:hover, &:focus-within {
      color: var(--color-800);
      text-decoration: underline;
    }
  }

  &__body {
    overflow: hidden;
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;

    &._show {
      opacity: 1;
      visibility: visible;
    }

    > div:last-of-type {
      border-bottom: 0;
    }
  }

  &--no-border {
    padding: 0 0 8px;

    .accordion {
      &-button {
        padding: 0 0 6px;
        color: var(--color-main);
        background-color: transparent;
        transition: color var(--switch-theme-time);

        &._active {
          color: var(--green-main);
        }
      }

      &__body {
        display: flex;
        flex-direction: column;
        gap: 12px;

        a {
          text-decoration: none;
          transition: color var(--switch-theme-time);
          display: flex;

          &:hover {
            color: var(--color-main);
          }

          &:before {
            display: inline-block;
            margin-right: 4px;
            content: "– ";
          }

        }
      }
    }

    .chevrone {
      transition: all var(--switch-theme-time);
    }

    &._active {
      .chevrone {
        transform: rotate(180deg);
      }
    }
  }
}