@import "./src/styles/variables.scss";

.ver {
  &__root {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  &__block {
    display: flex;
    flex-direction: row;
    padding: 8px;
    align-items: center;

    &__money {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      border-radius: 4px;
      padding: 12px;
      background-color: var(--white-200);
      color: var(--white-main);

      &:hover {
        outline: 2px solid var(--green-main);
      }

      .light & {
        background-color: rgba(31, 33, 33, 0.06);
        color: rgba(31, 33, 33, 1);
      }
    }

    &__control {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      border-radius: 4px;
      padding: 0px 12px;
      background-color: var(--green-400);
      cursor: pointer;

      &-gray{
        background-color: var(--color-200);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        border-radius: 4px;
        padding: 0px 12px;
        cursor: pointer;

        &:hover{
          background-color: var(--color-400);
        }
      }

      &:hover {
        color: var(--green-accent);
        background-color: var(--white-400);
      }

      @media (max-width: $tablet) {
        padding: 8px;
      }
    }

    &__info {
      padding: 7px 0px 6px 0px;
      height: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 4px;

      span{
        line-height: 13px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
