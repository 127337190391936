@import "src/styles/variables";

.favorites-page {
    .navigation{
        &__nav {
            margin-top: 24px;
            margin-bottom: 40px;

            @media (max-width: $mobileSmall) {
                margin-bottom: 20px;
            }

            > .checkbox {
                display: none;
                //&:nth-of-type(1),
                //&:nth-of-type(2){
                //    display: flex;
                //}
                @media(min-width: $mobileExtraSmall) {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3){
                        display: flex;
                    }
                }
                @media(min-width: $mobileSmall) {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(4){
                        display: flex;
                    }
                }
                @media(min-width: $mobile) {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(4),
                    &:nth-of-type(5),
                    &:nth-of-type(6){
                        display: flex;
                    }
                }

                @media(min-width: $tabletSmall) {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(4),
                    &:nth-of-type(5),
                    &:nth-of-type(6),
                    &:nth-of-type(7),
                    &:nth-of-type(8) {
                        display: flex;
                    }
                }

                @media(min-width: $tablet) {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(4),
                    &:nth-of-type(5),
                    &:nth-of-type(6),
                    &:nth-of-type(7),
                    &:nth-of-type(8),
                    &:nth-of-type(9) {
                        display: flex;
                    }
                }
            }
        }

        &__more{
            display: block;
            .chevrone{
                @media(max-width: $mobileExtraSmall){
                    margin-left: auto;
                }
            }
            &, > *{
                @media(max-width: $mobileExtraSmall){
                    width: 100%;
                }
            }
            @media(min-width: $tablet){
                display: none;
            }

            .dropdown__body{
                left: auto;
                right: 0;
            }

            .checkbox {
                @media(min-width: $mobileExtraSmall) {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3){
                        display: none;
                    }
                }
                @media(min-width: $mobileSmall) {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(4){
                        display: none;
                    }
                }

                @media(min-width: $mobile) {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(4),
                    &:nth-of-type(5),
                    &:nth-of-type(6){
                        display: none;
                    }
                }

                @media(min-width:$tabletSmall ) {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(4),
                    &:nth-of-type(5),
                    &:nth-of-type(6),
                    &:nth-of-type(7),
                    &:nth-of-type(8){
                        display: none;
                    }
                }

                @media(min-width: $tablet) {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(4),
                    &:nth-of-type(5),
                    &:nth-of-type(6),
                    &:nth-of-type(7),
                    &:nth-of-type(8),
                    &:nth-of-type(9) {
                        display: none;
                    }
                }
            }
        }
    }

    &__card{
        background-color: var(--color-300);
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        @media(max-width: $mobileSmall) {
            border-radius: 16px;
        }

        .girl-card{
            height: 100%;
        }

        &-box{
            padding: 20px 20px 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 8px;
            min-height: 88px;
            @media(max-width: $mobileExtraSmall) {
                padding: 20px;
            }
            > div{
                display: flex;
                gap: 6px;
                align-items: center;
            }
        }
    }
}