.switch {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
  width: 150px;
  height: 32px;
  background: var(--color-200);
  border-radius: 4px;
}

.switch-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  color: var(--color-600);
  cursor: pointer;
}

.switch-input {
  appearance: none;
  position: absolute;
  height: 100%;
  width: 50px;
  &-y {
    left: 0;
  }

  &-a {
    left: 50%;
    transform: translateX(-50%);
  }

  &-n {
    right: 0;
  }
}

.switch-input:checked + .switch-label {
  color: var(--color-main);
  transition: all 0.1s ease 0s;
}

.switch-input:checked + .switch-label-y ~ .switch-selector {
  transform: translate(0%, -50%);
}
.switch-input:checked + .switch-label-i ~ .switch-selector {
  transform: translate(100%, -50%);
}
.switch-input:checked + .switch-label-n ~ .switch-selector {
  transform: translate(200%, -50%);
}

.switch-selector {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  display: block;
  width: 50px;
  height: 26px;
  border-radius: 4px;
  background-color: var(--color-400);
  transition: all 0.1s ease 0s;
}
