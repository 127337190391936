@import "src/styles/variables";


.girl-map{
    &__container{
        padding-top: 31px;
        padding-bottom: 31px;
        @media(max-width: $mobile){
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &__inner{
        border-radius: 24px;
        overflow: hidden;
        position: relative;
        background: url("/assets/img/map-bg.webp") no-repeat center center/cover;
        //max-width: 1400px !important;
        &:after{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--black-700);
            .light &{
                background-color: var(--white-700);
            }
        }
    }

    &__content{
        z-index: 1;
        padding: 70px 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        color: var(--color-main);
        @media(max-width: $mobile){
            padding: 32px;
        }
        @media(max-width: $mobileSmall){
            text-align: center;
        }
        .title_h1{
            margin-bottom: 15px;
            text-align: center;
            text-wrap: balance;
            @media(max-width: $mobileExtraSmall){
                text-wrap: pretty;
            }
        }
        p:not(.title_h1){
            margin-bottom: 34px;
            font-weight: 600;
            @media(max-width: $mobile){
                font-size: 14px;
                margin-bottom: 24px;
            }
        }
    }
}