@import "./variables";

.w-100 {
  width: 100%;
}
.w-25{
  width: 25%;
}
.w-50 {
  width: 50%;
}
.w-min-50{
  min-width: 50%;
}
.w-75 {
  width: 75%;
}

.w-min-fill{
  min-width: 100%;
}

.border-default{
  border-radius: 4px;
  border: 1px solid var(--color-400);
}

.w-100p{
  min-width: 100px;
  width: 100px;
}

.w-140p{
  min-width: 140px;
  width: 140px;
}

.w-360 {
  width: 360px !important;
}
.w-mc {
  width: max-content;
}
.max-w-100 {
  max-width: 100% !important;
}
.max-w-none {
  max-width: none !important;
}
.min-w-160 {
  min-width: 160px;
}
.min-w-100 {
  min-width: 100px;
}

.min-h-800 {
  min-height: 800px;
}

.min-w-fit {
  min-width: fit-content;
}

.vis-hid {
  visibility: hidden;
}

.h-40 {
  min-height: unset;
  height: 40px;
  max-height: 40px;
}

.h-60{
  height: 60px;
}
.pl-0 {
  padding-left: 0;
}
.p-h-24{
  padding-left: 24px;
  padding-right: 24px;
}
.pr-0 {
  padding-right: 0;
}
.m-0-20{
  margin-left: 20px;
  margin-right: 20px;
}
.m-12-24 {
  margin: 12px 24px;
}
.ml-auto {
  margin-left: auto !important;
}

.ml-12{
  margin-left: 12px;
}

.ml-16{
  margin-left: 16px;
}

.ml-22{
  margin-left: 22px;
}

.mr-auto {
  margin-right: auto;
}

.mr-4{
  margin-right: 4px;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mr-4 {
  margin-right: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16{
  margin-left: 16px;
}

.mr-0{
  margin-right: 0px !important;
}

.mr-8{
  margin-right: 8px;
}

.mr-12{
  margin-right: 12px !important;
}

.mb-4-mob {
  @media (max-width: $mobile) {
    margin-bottom: 4px;
  }
}

.mb-8-mob {
  @media (max-width: $mobile) {
    margin-bottom: 8px;
  }
}

.mb-12-mob {
  @media (max-width: $mobile) {
    margin-bottom: 12px;
  }
}

.mb-16-mob {
  @media (max-width: $mobile) {
    margin-bottom: 16px;
  }
}

.mb-20-mob {
  @media (max-width: $mobile) {
    margin-bottom: 20px;
  }
}

.mb-24-mob {
  @media (max-width: $mobile) {
    margin-bottom: 24px;
  }
}

.mb-32-mob {
  @media (max-width: $mobile) {
    margin-bottom: 32px;
  }
}

.mb-36 {
  @media (max-width: $mobile) {
    margin-bottom: 36px;
  }
}

.mb-40-mob {
  @media (max-width: $mobile) {
    margin-bottom: 40px;
  }
}

.mb-48-mob {
  @media (max-width: $mobile) {
    margin-bottom: 48px;
  }
}

.mt-4-mob {
  @media (max-width: $mobile) {
    margin-top: 4px;
  }
}

.mt-8-mob {
  @media (max-width: $mobile) {
    margin-top: 8px;
  }
}

.mt-12-mob {
  @media (max-width: $mobile) {
    margin-top: 12px;
  }
}

.mt-16-mob {
  @media (max-width: $mobile) {
    margin-top: 16px;
  }
}

.mt-20-mob {
  @media (max-width: $mobile) {
    margin-top: 20px;
  }
}

.mt-24-mob {
  @media (max-width: $mobile) {
    margin-top: 24px;
  }
}

.mt-32-mob {
  @media (max-width: $mobile) {
    margin-top: 32px;
  }
}

.mt-36 {
  @media (max-width: $mobile) {
    margin-top: 36px;
  }
}

.mt-40-mob {
  @media (max-width: $mobile) {
    margin-top: 40px;
  }
}

.mt-48-mob {
  @media (max-width: $mobile) {
    margin-top: 48px;
  }
}

.mt-auto {
  margin-top: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6{
  margin-top: 6px;
}

.mb-6{
  margin-bottom: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10{
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-64{
  margin-top: 64px;
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 8px;
}
.p-24 {
  padding: 24px;
}

.p-0-28 {
  padding: 0 28px;
}

.p-r-8 {
  padding-right: 8px;
}

.fz-10 {
  font-size: 10px;
}

.fz-11 {
  font-size: 11px;
}

.fz-12 {
  font-size: 12px;
}

.fz-13 {
  font-size: 13px;
  @media (max-width: $mobile) {
    font-size: 12px;
  }
}

.fz-14 {
  font-size: 14px;
}

.fz-15 {
  font-size: 15px;
  @media (max-width: $mobile) {
    font-size: 14px;
  }
}

.fz-16 {
  font-size: 16px;
}

.fz-17 {
  font-size: 17px;
}

.fz-18 {
  font-size: 18px;
  @media (max-width: $tablet) {
    font-size: 15px;
  }
}

.fz-19 {
  font-size: 19px;
}

.fz-20 {
  font-size: 20px;
}

.fz-21 {
  font-size: 21px;
}

.fz-22 {
  font-size: 22px;
}

.fz-23 {
  font-size: 23px;
}

.fz-24 {
  font-size: 24px;
}

.fz-25 {
  font-size: 25px;
}

.fz-26 {
  font-size: 26px;
}

.fz-27 {
  font-size: 27px;
}

.fz-28 {
  font-size: 28px;
}

.fz-32 {
  font-size: 32px;
  line-height: 42px;
}

.fz-10-mob {
  @media (max-width: $mobile) {
    font-size: 10px;
  }
}

.fz-11-mob {
  @media (max-width: $mobile) {
    font-size: 11px;
  }
}

.fz-12-mob {
  @media (max-width: $mobile) {
    font-size: 12px;
  }
}

.fz-13-mob {
  @media (max-width: $mobile) {
    font-size: 13px;
  }
}

.fz-14-mob {
  @media (max-width: $mobile) {
    font-size: 14px;
  }
}

.fz-15-mob {
  @media (max-width: $mobile) {
    font-size: 15px;
  }
}

.fz-16-mob {
  @media (max-width: $mobile) {
    font-size: 16px;
  }
}

.fz-17-mob {
  @media (max-width: $mobile) {
    font-size: 17px;
  }
}

.fz-18-mob {
  @media (max-width: $mobile) {
    font-size: 18px;
  }
}

.fz-19-mob {
  @media (max-width: $mobile) {
    font-size: 19px;
  }
}

.fz-20-mob {
  @media (max-width: $mobile) {
    font-size: 20px;
  }
}

.fz-21-mob {
  @media (max-width: $mobile) {
    font-size: 21px;
  }
}

.fz-22-mob {
  @media (max-width: $mobile) {
    font-size: 22px;
  }
}

.fz-23-mob {
  @media (max-width: $mobile) {
    font-size: 23px;
  }
}

.fz-24-mob {
  @media (max-width: $mobile) {
    font-size: 24px;
  }
}

.fz-25-mob {
  @media (max-width: $mobile) {
    font-size: 25px;
  }
}

.fz-26-mob {
  @media (max-width: $mobile) {
    font-size: 26px;
  }
}

.fz-27-mob {
  @media (max-width: $mobile) {
    font-size: 27px;
  }
}

.fz-28-mob {
  @media (max-width: $mobile) {
    font-size: 28px;
  }
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.bold {
  font-weight: 500;
}

.lh-14 {
  line-height: 14px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

.lh-20 {
  line-height: 20px;
}

.lh-22 {
  line-height: 22px;
}

.lh-24 {
  line-height: 24px;
}

.lh-26 {
  line-height: 26px;
}

.lh-28 {
  line-height: 28px;
}

.lh-34 {
  line-height: 34px;
}

.lh-54 {
  line-height: 54px;
}

.no-border {
  border: none !important;
}

.br-4 {
  border-radius: 4px;
}

.br-24 {
  border-radius: 24px;
}

.br-default{
  border-radius: 4px;
  border: 1px solid var(--color-400);
}

.height-100 {
  height: 100%;
}
.height-fit {
  height: fit-content;
}

.width-80 {
  width: 80%;
}

.width-max {
  width: max-content;
}

.width-min {
  width: min-content;
}

.width-fit {
  width: fit-content;
}

.grid-col-1 {
  display: grid;
  grid-column: 1;
}

.grid-col-3 {
  display: grid;
  grid-column: 3;
}

.grid-col-2 {
  grid-column: span 2 / span 2;
}

.grid-tp-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-tp-col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.d-flex {
  display: flex;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.d-inline-flex {
  display: inline-flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-no {
  flex-wrap: nowrap;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.d-grid {
  display: grid;
}

.justify-self-center{
  justify-self: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-sb {
  justify-content: space-between;
}
.justify-se {
  justify-content: space-evenly;
}

.justify-start {
  justify-content: start;
}

.align-self-start{
  align-self: flex-start;
}

.align-self-center{
  align-self: center;
}

.align-center {
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}
.felx-sh{
  flex-shrink: 2;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.fd-column {
  display: flex;
  flex-direction: column;
}

.fd-column-rev {
  flex-direction: column-reverse;
}

.fd-row {
  display: flex;
  flex-direction: row;
}



.gap-0 {
  gap: 0;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-24 {
  gap: 24px;
}

.gap-28 {
  gap: 28px;
}

.gap-32 {
  gap: 32px;
}

.gap-40 {
  gap: 40px;
}

.td-none{
 text-decoration: none;
}

.old-price {
  color: var(--color-600);
  text-decoration: line-through;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.text-nowrap {
  white-space: nowrap;
}

.bg-none {
  background-color: transparent;
}

.bg-green {
  background-color: var(--green-main);
}

.bg-green-400 {
  background-color: var(--green-400);
}

.bg-green-300 {
  background-color: var(--green-300);
}

.bg-color-500 {
  background-color: var(--color-500);
}

.bg-color-300 {
  background-color: var(--color-300);
}

.bg-color-200 {
  background-color: var(--color-200);
}

.color-green {
  color: var(--green-main) !important;
}
.color-black {
  color: var(--black-main) !important;
}

.no-break {
  word-break: keep-all; /* Prevents breaking */
}

.color-white {
  color: var(--white-main) !important;
}

.color-700 {
  color: var(--color-700) !important;
}

.color-600 {
  color: var(--color-600) !important;
}

.color-500 {
  color: var(--color-500) !important;
}

.color-main {
  color: var(--color-main) !important;
}

.color-main-imp {
  color: var(--color-main) !important;
}

.color-gold-main {
  color: var(--gold-main);
}

.color-gold-second {
  color: var(--gold-second);
}

.color-main-reverse {
  color: var(--color-main-reverse);
}

.color-red-700 {
  color: var(--red-main);
}

[hidden] {
  display: none;
}

.display-none {
  display: none !important;
}
.mobile-fd-col {
  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.display-none-mobile-small {
  @media (max-width: $mobileSmall) {
    display: none !important;
  }
}

.display-none-mobile-small-reverse {
  @media (min-width: $mobileSmall) {
    display: none !important;
  }
}

.display-none-mobile {
  @media (max-width: $mobile) {
    display: none !important;
  }
}

.display-none-mobile-reverse {
  @media (min-width: $mobile) {
    display: none !important;
  }
}

.d-none-tablet {
  @media (max-width: $tablet) {
    display: none !important;
  }
}

.d-none-tablet-small {
  @media (max-width: $tabletSmall) {
    display: none !important;
  }
}

.d-none-tablet-small-reverce {
  @media (min-width: $tabletSmall) {
    display: none !important;
  }
}
.display-none-init {
  @media (max-width: $maxWidthContainer) {
    display: none !important;
  }
}

.event-none {
  pointer-events: none;
}

.disabled-global {
  opacity: 40%;
  pointer-events: none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix {
  content: "";
  display: table;
  clear: both;
}
.min-w-auto {
  min-width: auto;
}
.pos-relative {
  position: relative !important;
}
.pos-unset {
  position: unset !important;
}

.pointer-events-none {
  pointer-events: none;
}

.p-el-1row{
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.p-el-4row{
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.p-el-3row{
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.capitalize{
  text-transform: capitalize;
}
.title_30{
  font-family: "Libertinus";
  line-height: 32px;
  font-size: 30px;
  color: var(--color-main);
}

.filter-invert{
  filter: invert(1);
}