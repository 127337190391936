@import "variables";

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-width: 360px;
  scroll-behavior: smooth;
  scroll-padding: 80px;
  @media (max-width: $mobile) {
    scroll-padding: 20px;
  }
}

body {
  font-family: "Inter";
  font-weight: 400;
  color: var(--color-700);
  line-height: 140%;
  font-size: 15px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (max-width: $mobile) {
    font-size: 14px;
  }
}

label {
  display: inline-block;
}

input,
button,
textarea {
  font-family: inherit;
  font-size: inherit;
  caret-color: var(--green-main);
}

input:autofill,
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background 0s 600000s, color 0s 600000s !important;
  -moz-transition: background 0s 600000s, color 0s 600000s !important;
}

/* Убираем стрелки в Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Убираем стрелки в Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none;
  background: transparent;
  color: inherit;
  appearance: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;

    &:hover,
    &:focus {
      -moz-appearance: textfield;
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

img {
  vertical-align: top;
  margin: 0;
}

hr {
  height: 1px;
  background-color: var(--color-300);
}
