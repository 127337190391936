.stars-box{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px;
    border: 1px solid var(--color-300);
    border-radius: 4px;
    color: var(--color-700);
    span{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.rating-stars{
    max-width: 66px;
}