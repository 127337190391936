.switch-theme {
  display: flex;
  position: relative;
  gap: 4px;
  width: max-content;
  background-color: var(--color-200);
  transition: background-color var(--switch-theme-time);
  border-radius: 12px;
  height: 24px;

  &__check {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    position: relative;
    transition: color 0.1s ease 0s;

    &._active {
      color: var(--black-main);
      i.icon--light{
      color: var(--green-secondary);
      }
      i.icon--dark{
        color: var(--black-light);
      }
    }

    &-bar {
      display: inline-block;
      width: 28px;
      background-color: var(--white-main);
      border-radius: 50%;
      position: absolute;
      top: 2px;
      bottom: 2px;
      transition: all 0.3s ease;
      right: 2px;
      left: 34px;

      &._active--light {
        left: 2px;
        right: auto;
      }
    }
  }

  &__check:not(._active){
    i.icon--light{
      color: var(--green-secondary);
    }
    i.icon--dark{
      color: var(--color-800);
    }
  }
}
