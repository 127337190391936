@import "src/styles/variables";

.adult-popup {
  z-index: 9999;
  &__body {
    color: var(--color-700);
    text-align: center;
    z-index: 2;
    border-radius: 4px;
    max-width: 400px;

    @media (max-width: $mobile) {
      padding: 16px;
    }
  }

  .switch-theme {
    background-color: var(--color-400);
    color: var(--color-600);
    &__check {
      width: 86px;
      &-bar {
        border-radius: 56px;
        &._active--dark {
          width: 86px;
          left: 92px;
        }

        &._active--light {
          width: 92px;
          left: 2px;
        }
      }
    }
  }
  &__top,
  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__top {
    position: relative;
    padding-top: 24px;
  }
  
  &__bottom {
    color: var(--color-main);
  }
  &__bottom p {
    margin-bottom: 20px;
  }
  &__bottom p a {
    color: var(--green-secondary);
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }

  &__actions {
    display: flex;
    gap: 8px;
    justify-content: center;

    .button{
      max-height: 32px;
      padding: 6px 24px 6px 12px;
    }
  }

  &__svg {
    height: 72px;
    width: 72px;
    font-size: 72px;
    color: var(--color-main);
  }

  
  &__city {
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--color-main);

    .select-city {
      position: relative;

      .select__body {
        flex-direction: column;
      }
    }
  }
  &__bottom {
    border-top: 1px solid var(--color-400);

    a {
      text-decoration: none;
    }
  }

  &__box {
    display: flex;
    gap: 12px;
    .button {
      width: 120px;
      justify-content: center;
    }
  }
}
