@import "./src/styles/variables.scss";
.dropdown-agency__actions {
    &.disabled {
        opacity: 0.4;
    }
    .dropdown__body  {
        width: 240px;
        color: var(--color-600);
        right: 0;
        left: unset;
        align-items: flex-start;
        gap: 6px;

        .button {
            color: var(--color-600);
            width: 100%;
            justify-content: flex-start;

            &.color-red-700 {
                color: var(--red-main);
            }
        }

        .d-flex {
            padding-left: 12px;
        }
    }
}

.agency-interior__images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    @media (max-width: $mobile) {
        grid-template-columns: repeat(2, 1fr);
    }

    .upload__zone {
        @media (max-width: $mobile) {
            width: 100%;
        }
    }
}