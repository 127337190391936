@import "src/styles/variables";

.new-girls{
    &__slider{
        padding-top: 28px !important;
        overflow: visible;
        @media(max-width: $mobile){
            padding-top: 18px;
        }
    }
}