@import "src/styles/variables";


.party-page{
    &__header{
        display: flex;
        justify-content: space-between;
        gap: 16px;
        padding-bottom: 32px;
        padding: 0 16px;
        align-items: center;
    }
}

.agency-party{
    & > section:not(.breadcrumbs, .agency__banner, .swiper),
    & > div:not(.breadcrumbs, .agency__banner, .swiper) {
        padding-top: 24px;
        padding-bottom: 24px;
        @media(max-width: $mobile) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    &__top{
        display: flex;
        gap: 32px;
        @media(max-width: 1200){
            flex-direction: column;
        }
    }

    &__list{
        ul{
            grid-gap: 0 24px;
            @media(max-width: $mobile){
                grid-template-columns: 1fr;
                grid-gap: 0;
            }
        }
        li{
            max-width: 100% !important;
            span:last-of-type{
                display: inline;
            }
        }
    }

    &__description{
        @media(min-width: 1100px){
            min-width: 560px;
            flex: 0 0 45%;
        }
        .model-header__address{
            padding-left: 28px;
            &:before{
                background-color: var(--color-500);
            }
        }
    }
    &__buttons{
        display: flex;
        gap: 4px;
        align-items: center;
        a:first-of-type{
            @media(max-width: $mobileExtraSmall){
                width: 100%;
            }
        }
    }
    .start-videochat__box{
        @media(max-width: $mobileSmall){
            padding: 10px 12px;
        }
        div{
            @media(max-width: $mobileSmall){
                display: flex;
                gap: 4px;
            }
        }
        span{
            font-size: 24px;
            margin-bottom: 0;
        }
    }
    .tabs{
        margin-bottom: 0;
        max-width: 710px;
        width: 100%;
        @media(max-width: $pc){
            //max-width: 550px;
            max-width: 100%;
        }
        @media(max-width: $tablet){
            //max-width: 480px;
        }
        @media(max-width: $tabletSmall){
            //max-width: 100%;
        }
    }
    //.agency__item{
    //    display: flex;
    //    align-items: center;
    //    h1{
    //        margin-bottom: 0;
    //    }
    //}
    //.agency__banner-img img{
    //    object-position: top;
    //}
    .parties-slider-wrapper{
        box-sizing: border-box;
        gap: 12px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;

       
    }
}
.parties__grid{
    display: grid;  
    grid-template-columns: repeat(4, 1fr);
    gap: 32px 8px;

    svg{
        margin: auto;
    }

    @media (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
    }
}
.party-card {
    background-color: var(--color-200);
    border-radius: 16px;

   

    &__img{
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        width: 100%;
        height: 205px;
        min-height: 205px;
        position: relative;
        overflow: hidden;
        display: block;
        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: scale(1);
            transition: all 0.3s ease-in-out;
        }
        &:hover, &:focus-within {
            img {
                transform: scale(1.1);
            }

            ~ .agency-card__content {
                .title_h3 {
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 4px;
                }
            }
        }
    }
    

    &__img.promo{
        border-radius: 4px;
    }
    &__img.promo:hover{
        cursor: pointer;
    }

    &__content{
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p{
            -webkit-box-orient: vertical;
             display: -webkit-box;
             -webkit-line-clamp: 4;
             overflow: hidden;
             text-overflow: ellipsis;
             white-space: normal;
        }
    }
    
    &__heading{
        display: flex;
        flex-direction: column;
    }

    &--private{
        cursor: help;
    }

    &__info {
        & > span {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: start;
            gap: 10px;
            margin-bottom: 12px;
        }
    }

    &__counts {
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: space-between;
    }

    &__count{
        display: flex;
        gap: 12px
    }

    

    &__country{
        display: flex;
        gap: 4px;
        align-items: center;
    }

    .agency-card__heading > div {
        justify-content: space-between;
    }

    .agency-card__counts {
        justify-content: space-between;
    }
}

.party-card:has(div.promo){
    background-color: transparent;
    height: 580px;
    max-height: 580px;
    div{
        height: 100%;
        img{
            min-height: 100%;
        }
    }
}

.change-party{
    &__body{
        display: grid;
        grid-template-columns: 65% 1fr;
        gap: 64px;

        .contacts{
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            .input-label.__error{
                span{
                    position: relative;
                    top: -60px;
                }
            }
            @media (max-width: 999px) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
            }
        }

        .interior-photo-container{
            .agency-interior{
                &__images{
                    grid-template-columns: repeat(2, 1fr);

                    @media (max-width: $mobileSmall) {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }

        .right-side{
            min-width: 250px;
            @media (max-width: 999px) {
                display: none;
            }

            .party-feature-name{
                display: grid;
                grid-template-columns: auto 40px;
            }
        }
        .first-row{
            display: grid;
            grid-template-columns: 3fr 2fr ;
            gap: 16px;
            @media (max-width: 999px) {
                display: flex;
                flex-wrap: wrap;
                
            }
        }

        .address-row{
            display: grid;
            grid-template-columns: calc(25% - 12px) 3fr 60px;
            gap: 16px;

            .selectors{

                .select-countries{
                    width: 100%;
                    .select__button{
                        border: 1px solid var(--color-400);
                    }
                }
            }

            .search{
                label{
                    background-color: var(--color-200);
                    &:hover{
                        background-color: var(--color-400);
                    }
                }
            }

            @media (max-width: 999px) {
                display: grid;
                grid-template-rows: repeat(2, 1fr);
                grid-template-columns: 1fr;
                .selectors{
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 60px;
                    gap:16px;
                }

                .search{
                    min-width: 100%;

                    .search__label{
                        background-color: var(--color-300);
                    }
                }
            }
           
        }

        .tablet-hidden{
            display: none;
        }

        .fullscreen{
            display: block;
        }

        @media (max-width: 999px) {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .tablet-hidden{
                display: block;
            }

            .fullscreen{
                display: none;
            }
        }
    }
}

.cards-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr ;
    }
}
.party-feature{
    padding: 12px;
    border-radius: 4px;
    background-color: var(--black-light);
    
    .button{
        align-self: flex-start;
    }
}

.prices-block {
    display: grid;
    grid-template-columns: 3fr 3fr 2fr;
}


.participate-popup{
    gap: 16px;
    display: flex;
    flex-direction: column;
    border: none;
    border-radius: 0;
}

.bottom-buttons{
    margin-top: 32px;
    justify-content: end;
    @media (max-width: 999px) {
        justify-content: space-between;
        flex-direction: row-reverse;
    }
}

.feature-buttons{
    margin-top: 8px;
    display: grid;
    grid-template-columns: auto 60px;
    gap: 8px;
}

.tarifs-row-trash {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    max-width: 40px;
    padding: 6px;

    color: var(--red-main);
    i{
        font-size: 1.5em;
    }

    &:hover{
        background-color: transparent;
        border: 1px solid var(--red-main);
    }
  }