@import "./src/styles/variables.scss";

.reviews-page {
  &__filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
