@import "./src/styles/variables.scss";

.ticket {
  &__root {
    display: flex;
    flex-direction: column;
    background-color: var(--color-200);
    width: 100%;
    padding: 20px;
    border-radius: 16px;
    gap: 16px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__theme {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: flex-start;
  }
}
