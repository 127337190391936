@import "src/styles/variables";

.avatar {
    display: flex;
    gap: 8px;
    align-items: center;
    text-decoration: none;

    &__icon,
    &--only-icon {
        border-radius: 50%;
        background-color: var(--color-400);
        color: var(--color-main);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        transition: all 0.1s ease-in;
    }

    &__text {
        color: var(--color-700);
        transition: all 0.1s ease-in;

        &:after {
            content: "Silver";
        }

        @media (max-width: $mobile) {
            display: none;
        }
    }

    &:hover,
    &:focus-within {
        .avatar__icon {
            background-color: var(--color-300);
        }

        .avatar__text {
            color: var(--color-main);
        }
    }

    &-size {
        &-xl {
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
        }

        &-m {
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
        }

        &-s {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
        }
    }

    &--gold {
        .avatar__icon,
        &.avatar--only-icon {
            background: linear-gradient(
                            87.32deg,
                            #b07d20 -26.05%,
                            #b07d20 0.29%,
                            #ffda44 33.85%,
                            #ffda44 61.27%,
                            #b07d20 84.83%,
                            #b07d20 120.09%
            );
        }

        .avatar__text {
            background: linear-gradient(
                            86.39deg,
                            #b07d20 -24.34%,
                            #b07d20 8.72%,
                            #ffda44 50.87%,
                            #ffda44 85.29%,
                            #b07d20 114.88%,
                            #b07d20 159.16%
            ),
            #ffffff;

            &:after {
                content: "Gold";
            }
        }
    }

    &--platinum {
        .avatar__icon,
        &.avatar--only-icon {
            background: linear-gradient(
                            133.45deg,
                            #ffffff -6.42%,
                            #ababab 13.47%,
                            #ffffff 30.01%,
                            #a8a8a8 58.89%,
                            #ffffff 83.98%,
                            #838383 109.29%
            );

            i:after {
                background-color: #4c4c4c;
            }
        }

        .avatar__text {
            background: linear-gradient(
                            133.45deg,
                            #ffffff -6.42%,
                            #ababab 13.47%,
                            #ffffff 30.01%,
                            #a8a8a8 58.89%,
                            #ffffff 83.98%,
                            #838383 109.29%
            );

            &:after {
                content: "Platinum";
            }
        }
    }

    &--gold,
    &--platinum {
        .avatar__text {
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &--admin {
        .avatar__text:after {
            content: "Admin";
        }
    }

    &--moderator {
        .avatar__text:after {
            content: "Moderator";
        }
    }


    &--indi {
        .avatar__text:after {
            content: "Indi";
        }
    }

    &--agency {
        .avatar__text:after {
            content: "Agency";
        }
    }

    &--photograph {
        .avatar__text:after {
            content: "Photograph";
        }
    }
}
