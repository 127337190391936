@import "./src/styles/variables.scss"; 


.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
  transition: all 0.2s;

  &:hover,
  &:focus-within {
    background-color: var(--color-200);

    input {
      &:after {
        background-color: var(--color-main);
      }
    }
  }

  .icon-flag{
    cursor: pointer;
  }

  &-icon {
    position: absolute;
    pointer-events: none;
    user-select: none;
    opacity: 0;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    transition: opacity 0.2s ease;
  }

  &-link{
    justify-content: center;
    background-color: var(--green-400);

    &:hover{
      background-color: var(--green-500);
    }
  }

  

  input {
    appearance: none;
    width: 16px;
    position: absolute;
    height: 16px;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    border: 1px solid var(--color-500);
    transition: all 0.1s ease 0s;
    border-radius: 4px;

    &.radio {
      border-radius: 50px;
    }

    &:before {
      content: unset;
    }

    &:after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.15s ease 0s;
      opacity: 0;
      mask-size: 100%;
      mask-repeat: no-repeat;
      mask-position: bottom center;
      -webkit-mask-position: bottom center;
      background-color: transparent;
      mask-image: url("/assets/img/icons/check.svg");
    }

    &:active:not([disabled]) {
      border-color: var(--color-main);
      background-color: var(--color-main);
    }

    &:checked {
      background-color: var(--color-main);

      ~ label {
        color: var(--color-main);
      }

      &:after {
        background-color: var(--color-main-reverse);
        opacity: 1;
      }
    }
  }

  // .checkbox__label
  label {
    cursor: pointer;
    border-radius: 4px;
    transition: color 0.2s ease 0s;
    padding: 6px 8px 6px 28px;
    font-weight: 400;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &_disabled {
    user-select: none;
    touch-action: none;
    cursor: default;
    opacity: 0.6;
    &:hover,
    &:focus-within {
      background-color: transparent;

      input {
        border: 2px solid var(--white-200);
      }
    }

    label {
      color: var(--color-500);
      cursor: default;
    }

    input {
      border: 2px solid var(--color-200);
      cursor: default;
      &:hover,
      &:focus-within,
      &:active:not([disabled]) {
        border: 2px solid var(--color-200);
      }
    }
  }

  &._important{
    input:checked {
      background-color: var(--color-main-reverse);

      ~ label {
        color: var(--color-main-reverse);
        background-color: var(--color-800);

        &:hover,
        &:focus-within {
          background-color: var(--color-main);
        }
      }

      &:after {
        background-color: var(--color-main);
      }
    }
  }

  &._no-border {
    display: inline-block;
    position: relative;
    &.checkbox_checked {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    &:hover,
    &:focus-within {
      background-color: transparent;
    }

    input {
      &:checked {
        border: 2px solid var(--color-main);
        background-color: var(--color-main);
        &:after {
          background-color: var(--color-main-reverse);
        }
        ~ label {
          color: var(--color-main);
        }
      }
    }

    svg {
      left: 2px;
    }

    label {
      padding: 0 0 0 22px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.link-checkbox{
  border-radius: 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all var(--switch-theme-time);
  aspect-ratio: 1/1;
  text-decoration: none !important;
}

.checkbox-button {
  height: 40px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
  background-color: var(--color-300);
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  min-width: fit-content;
  display: flex;
  label{
    width: 100%;
    align-self: center;
    cursor: pointer;
    padding: 0px 25px;
  }

  &:hover,
  &:focus-within {
    background-color: var(--green-500);
  }

  input {
    appearance: none;
    width: 0;
    height: 0;
  }

  &_checked {
    background-color: var(--green-400) !important;
    border: 1px solid var(--green-accent);

    &:hover,
    &:focus-within {
      background-color: var(--green-600) !important;
    }
  }

  &_disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  @media (max-width: $mobile) {
    height: 32px;
    padding: 10px 12px !important;
  }
}
