:root {
  --gold-main: #ccaf6e;
  --gold-second: #ffcc4a;
  --gold-800: rgba(204, 175, 110, 0.9);
  --gold-700: rgba(204, 175, 110, 0.8);
  --gold-600: rgba(204, 175, 110, 0.6);
  --gold-500: rgba(204, 175, 110, 0.4);
  --gold-400: rgba(204, 175, 110, 0.2);
  --gold-300: rgba(204, 175, 110, 0.12);
  --gold-200: rgba(204, 175, 110, 0.08);
  --gold-100: rgba(204, 175, 110, 0.04);
}

:root {
  --red-main: #dc3d50;
  --red-800: rgba(220, 61, 80, 0.9);
  --red-700: rgba(220, 61, 80, 0.8);
  --red-600: rgba(220, 61, 80, 0.6);
  --red-500: rgba(220, 61, 80, 0.4);
  --red-400: rgba(220, 61, 80, 0.2);
  --red-300: rgba(220, 61, 80, 0.12);
  --red-200: rgba(220, 61, 80, 0.08);
  --red-100: rgba(220, 61, 80, 0.04);
}

:root {
  --green-secondary: #C7F5EA;
  --green-accent: #0fd0a3;
  --green-another: #157a63;
  --green-main: #1ca182;
  --green-800: rgba(28, 161, 130, 0.9);
  --green-700: rgba(28, 161, 130, 0.8);
  --green-600: rgba(28, 161, 130, 0.6);
  --green-500: rgba(28, 161, 130, 0.4);
  --green-400: rgba(28, 161, 130, 0.2);
  --green-300: rgba(28, 161, 130, 0.12);
  --green-200: rgba(28, 161, 130, 0.08);
  --green-100: rgba(28, 161, 130, 0.04);
}

:root {
  --gray-main: #181818;
  --gray-800: rgba(24, 24, 24, 0.9);
  --gray-700: rgba(24, 24, 24, 0.8);
  --gray-600: rgba(24, 24, 24, 0.6);
  --gray-500: rgba(24, 24, 24, 0.4);
  --gray-400: rgba(24, 24, 24, 0.2);
  --gray-300: rgba(24, 24, 24, 0.12);
  --gray-200: rgba(24, 24, 24, 0.08);
  --gray-100: rgba(24, 24, 24, 0.04);
}

:root {
  --white-main: #fff;
  --white-800: rgba(255, 255, 255, 0.9);
  --white-700: rgba(255, 255, 255, 0.8);
  --white-600: rgba(255, 255, 255, 0.6);
  --white-500: rgba(255, 255, 255, 0.4);
  --white-400: rgba(255, 255, 255, 0.2);
  --white-300: rgba(255, 255, 255, 0.12);
  --white-200: rgba(255, 255, 255, 0.08);
  --white-100: rgba(255, 255, 255, 0.04);
}

:root {
  --black-light: #1a1a1a;
  --black-main: #000;
  --black-800: rgba(0, 0, 0, 0.9);
  --black-700: rgba(0, 0, 0, 0.8);
  --black-600: rgba(0, 0, 0, 0.6);
  --black-500: rgba(0, 0, 0, 0.4);
  --black-400: rgba(0, 0, 0, 0.2);
  --black-300: rgba(0, 0, 0, 0.12);
  --black-200: rgba(0, 0, 0, 0.08);
  --black-100: rgba(0, 0, 0, 0.04);
}

:root {
  --switch-theme-time: 0.15s ease 0s;
  --box-shadow-big: 0 10px 20px 0 var(--color-200);
  --box-shadow-main: 0 10px 20px 0 var(--white-400);
  --border-radius-xs: 4px;
  --border-radius-small: 8px;
  --border-radius-medium: 12px;
}

:root {
  --bg-image: url("/assets/img/main-page-bg.webp");
  --bg-image-mobile: url("../assets/img/main-page-bg-mobile.webp");

  --about-block: url("../assets/img/about-bg-dark.webp");
  --benefits-block: url("../assets/img/benefits-bg-dark.webp");

  --bg-main: var(--black-main);

  --bg-footer-main: var(--white-300);
  --bg-footer-second: var(--black-500);

  --dropdown-bg-hover: var(--white-500);

  --color-main-reverse: var(--black-main);
  --color-800-reverse: var(--black-800);
  --color-700-reverse: var(--black-700);
  --color-600-reverse: var(--black-600);
  --color-500-reverse: var(--black-500);
  --color-400-reverse: var(--black-400);
  --color-300-reverse: var(--black-300);
  --color-200-reverse: var(--black-200);
  --color-100-reverse: var(--black-100);

  --color-main: var(--white-main);
  --color-800: var(--white-800);
  --color-700: var(--white-700);
  --color-600: var(--white-600);
  --color-500: var(--white-500);
  --color-400: var(--white-400);
  --color-300: var(--white-300);
  --color-200: var(--white-200);
  --color-100: var(--white-100);
}

.light {
  --bg-image: url("/assets/img/main-page-bg-light.webp");
  --bg-image-mobile: url("../assets/img/main-page-bg-mobile-light.webp");
  
  --about-block: url("../assets/img/about-bg-light.webp");
  --benefits-block: url("../assets/img/benefits-bg-light.webp");
  --bg-main: var(--white-main);

  --bg-footer-main: var(--black-200);
  --bg-footer-second: var(--white-500);

  --dropdown-bg-hover: var(--black-400);

  --box-shadow-big: 0px 16px 10px 10px var(--black-400);
  --box-shadow-main:  0 10px 20px 0 var(--black-400);

  --green-accent: #0cd6d4;
  --green-main: #0ab8b6;
  --green-secondary: var(--color-600);
  --green-800: rgba(10, 184, 182, 0.9);
  --green-700: rgba(10, 184, 182, 0.8);
  --green-600: rgba(10, 184, 182, 0.6);
  --green-500: rgba(10, 184, 182, 0.4);
  --green-400: rgba(10, 184, 182, 0.2);
  --green-300: rgba(10, 184, 182, 0.12);
  --green-200: rgba(10, 184, 182, 0.08);
  --green-100: rgba(10, 184, 182, 0.04);

  --color-main-reverse: var(--white-main);
  --color-800-reverse: var(--white-800);
  --color-700-reverse: var(--white-700);
  --color-600-reverse: var(--white-600);
  --color-500-reverse: var(--white-500);
  --color-400-reverse: var(--white-400);
  --color-300-reverse: var(--white-300);
  --color-200-reverse: var(--white-200);
  --color-100-reverse: var(--white-100);

  --black-light: #f0f0f0;
  --color-main: var(--black-main);
  --color-800: var(--black-800);
  --color-700: var(--black-700);
  --color-600: var(--black-600);
  --color-500: var(--black-500);
  --color-400: var(--black-400);
  --color-300: var(--black-300);
  --color-200: var(--black-200);
  --color-100: var(--black-100);

  --gray-main: #ededed;
  --gray-800: rgba(237, 237, 237, 0.9);
  --gray-700: rgba(237, 237, 237, 0.8);
  --gray-600: rgba(237, 237, 237, 0.6);
  --gray-500: rgba(237, 237, 237, 0.4);
  --gray-400: rgba(237, 237, 237, 0.2);
  --gray-300: rgba(237, 237, 237, 0.12);
  --gray-200: rgba(237, 237, 237, 0.08);
  --gray-100: rgba(237, 237, 237, 0.04);
}
