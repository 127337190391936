@import "src/styles/variables";

.toggle-switch {
  display: flex;
  align-items: center;
  width: max-content;
  

  a.hover-line{
    @media (max-width: $mobile) {
      padding: 0px 8px;
    }
    
  }

  &.defaulttitle {
    gap: 10px;
  }

  &._disabled,
  &[disabled] {
    filter: grayscale(0.5);
    opacity: 0.4;
    pointer-events: none;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    height: 24px;
    position: relative;
    width: 50px;
    &:before {
      background-color: var(--color-200);
      border-radius: 48px;
      color: var(--white-main);
      content: "Off";
      cursor: pointer;
      display: block;
      font-size: 13px;
      height: 100%;
      left: 0;
      padding: 4px 6px;
      position: absolute;
      text-align: right;
      top: 0;
      transition: background-color .2s;
      width: 100%;
    }

    &:after {
      background-color: var(--green-main);
      border-radius: 50%;
      box-shadow: 0 2px 3px #0000004d;
      content: "";
      cursor: pointer;
      display: block;
      height: 20px;
      left: 2px;
      position: absolute;
      top: 2px;
      transition: all .15s ease-in 0s;
      width: 20px;
    }

    &:checked {
      &:before {
        background-color: var(--green-main);
        content: "On";
        text-align: left;
      }

      &:after {
        background-color: var(--white-main);
        transform: translateX(calc(100% + 6px));
      }
    }
  }

  &.theme{
    font-family: "lady4love" !important;
    
    input{
      &:before{
        line-height: 18px;
        text-indent: 23px;
        content: "\77"; //light
        color: var(--white-main);
        background-color: var(--color-300);
        font-size: 18px;
      }
      &:after{
        display: inline-flex;
        padding: 2px 2px;
        line-height: 16px;
        content: "\72"; //dark
        background-color: var(--white-main);
        color: var(--green-main);
        font-size: 16px;
      }
      &:checked {
        &:before {
          line-height: 16px;
          background-color: var(--color-200);
          color: var(--green-secondary);
          content: "\72"; //dark
          text-align: left;
          font-size: 16px;
          text-indent: -2px;
        }
  
        &:after {
          content: "\77"; //light
          background-color: var(--white-main);
          color: var(--green-accent);
          transform: translateX(calc(100% + 6px));
          font-size: 18px;
          padding: 2.4px 1.2px;
        }
      }
    }

    
  }

  a {
    &:after {
      top: -6px;
      right: -12px;
    }
  }
}

//.toggle-switch {
//    position: relative;
//    width: 62px;
//    display: inline-block;
//    vertical-align: middle;
//    -webkit-user-select: none;
//    -moz-user-select: none;
//    -ms-user-select: none;
//    text-align: left;
//    &-checkbox {
//        display: none;
//    }
//    &-label {
//        display: block;
//        overflow: hidden;
//        cursor: pointer;
//        background-color: var(--color-400);
//        border-radius: 20px;
//        margin: 0;
//        transition: background-color 0.15s ease-in-out 0s;
//    }
//    &-inner {
//        display: block;
//        width: 200%;
//        margin-left: -100%;
//        transition: margin 0.15s ease-in 0s;
//        &:before,
//        &:after {
//            display: block;
//            float: left;
//            width: 50%;
//            height: 32px;
//            line-height: 32px;
//            font-size: 14px;
//            font-weight: 500;
//        }
//        &:before {
//            content: attr(data-yes);
//            color: var(--white-700);
//            padding-left: 5px;
//        }
//        &:after{
//            color: var(--color-500);
//        }
//    }
//    &-disabled {
//        background-color: $disabled-colour;
//        cursor: not-allowed;
//        &:before {
//            background-color: $disabled-colour;
//            cursor: not-allowed;
//        }
//    }
//    &-inner:after {
//        content: attr(data-no);
//        padding-right: 5px;
//        text-align: right;
//        color: var(--color-500);
//    }
//    &-switch {
//        display: block;
//        width: 28px;
//        height: 28px;
//        background-color: var(--green-main);
//        position: absolute;
//        top: 50%;
//        transform: translateY(-50%);
//        right: 32px;
//        border-radius: 20px;
//        transition: all 0.15s ease-in 0s;
//    }
//    &-checkbox:checked + &-label {
//        background-color: var(--green-main);
//        .toggle-switch-label{
//        }
//        .toggle-switch-inner {
//            margin-left: 0;
//        }
//        .toggle-switch-switch {
//            right: 2px;
//            background-color: var(--white-main);
//        }
//    }
//}
