@import "src/styles/variables";

.agency-block {
  display: flex;
  background-color: var(--color-100);
  align-items: center;
  padding: 4px 12px 4px 4px;
  gap: 20px;
  border-radius: 4px;

  &__link-to {
    width: 100%;
    color: var(--green-main);
    overflow: hidden;
    word-break: break-all;
    display: flex;
    gap: 12px;
    align-items: center;
    p{
      width: 100%;
    }
  }

  img {
    height: 86px;
    border-radius: 4px;
    object-fit: cover;
    aspect-ratio: 3/4;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 320px;
    overflow: hidden;
    overflow-y: auto;

    &.make-elite--agency {
      max-height: 320px;
      height: unset;
    }
  }
}

