.popup-order{
    &__address{
        min-height: 60px;
        justify-content: center;
    }
    .input-label__radio{
        padding: 8px;
        min-width: auto;
        width: 100%;
        min-height: 52px;
    }
}