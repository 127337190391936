@import "src/styles/variables";

.profile-cards {
    display: flex;
    gap: 8px;

    @media (max-width: $tabletSmall) {
        flex-wrap: wrap;
    }
}

.profile-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 18px 14px;
    background-color: var(--color-200);
    border-radius: 16px;
    @media (max-width: $tablet) {
        padding: 12px 14px;
    }

    @media (max-width: $tabletSmall) {
        height: auto;
    }

    @media (max-width: $mobile) {
        gap: 12px;
    }

    p {
        margin: 0;
        @media (max-width: $mobile) {
            font-size: 14px;
            line-height: 22px;
        }
    }

    > p {
        color: var(--color-main);
        margin-bottom: auto;
    }

    a {
        word-break: break-all;
        display: inline;
    }

    &__img {
        margin: 0 8px;
        @media (max-width: $tablet) {
            margin: 0px;
        }

        img {
            border-radius: 12px;
            object-fit: contain;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
}