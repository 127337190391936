.toggle-button {
    padding: 4px;
    display: flex;
    gap: 16px;
    background: var(--bg-footer-main);
    border-radius: 22px;

    .button:not(.active) {
        background: transparent;

        &:hover {
            background: var(--bg-footer-main);
        }
    }
    .button:focus-within{
        background-color: var(--color-400);
    }
}