@import 'src/styles/variables';

.benefits {
    &__container {
        padding-top: 31px;
        padding-bottom: 40px;
        margin-top: 84px;
        @media(max-width: $mobile) {
            padding-top: 124px;
            padding-bottom: 32px;
        }
    }

    &__content {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        border-radius: 40px;
        padding: 48px;
        background: var(--benefits-block) no-repeat center center/ cover;
        color: var(--color-main);
        position: relative;
        margin-bottom: 32px;
        @media(max-width: $pc) {
            flex-direction: column;
            gap: 48px;
        }
        @media(max-width: $tablet) {
            padding: 132px 48px 48px;
        }
        @media(max-width: $mobile) {
            padding: 96px 16px 16px;
        }

    }

    &__star {
        position: absolute;
        top: -80px;
        right: 50px;
        width: 246px;
        height: 246px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: url("/assets/img/benefits-block-star.webp") no-repeat center center/ contain;
        .light & {
            background: url("/assets/img/benefits-block-star-light.webp") no-repeat center center/ contain;
        }
        @media(max-width: $tablet) {
            width: 202px;
            height: 202px;
            top: -74px;
        }

        @media(max-width: $mobile) {
            top: -120px;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
        }

        p, span{
            color: var(--white-main);
            line-height: 115%;
        }

        p{
            font-weight: 600;
            font-size: 18px;
            width: 128px;
         
        }

        span{
            font-size: 24px;
        }


    }

    &__items, &__inner {
        position: relative;
        z-index: 1;
        display: flex;
    }

    &__inner {
        flex-direction: column;
        max-width: 586px;
        text-align: center;
        align-items: center;
        @media(min-width: $pc) {
            margin-right: 40px;
        }

        a {
            text-decoration: none;
        }

        .title_h2 {
            margin-bottom: 16px;
        }

        p:not(.title_30) {
            margin-bottom: 24px;
            font-size: 15px;
            @media(max-width: $mobile) {
                font-size: 14px;
            }
        }
    }

    &__items {
        flex-wrap: wrap;
        gap: 24px;
        justify-content: center;
        @media(min-width: $pc) {
            max-width: 634px;

        }
    }

    &__item {
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 150%;
        font-size: 15px;
        min-height: 160px;
        padding: 32px;
        width: 304px;
        border: 2px solid var(--color-200);
        position: relative;
        border-radius: 24px;
        background-color: var(--color-300-reverse);
        backdrop-filter: blur(52px);
        -webkit-backdrop-filter: blur(52px);

        &:nth-child(3), &:nth-child(6) {
            padding: 24px;
        }

        @media(max-width: $tablet) {
            min-height: auto;
            max-width: 296px;
            font-size: 14px;
        }

        i{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--white-main);
            background-color: var(--green-main);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
        }

    }

    &__counters {
        display: flex;
        justify-content: center;
        gap: 40px;
        flex-wrap: wrap;
        font-weight: 400;
        font-size: 15px;
        @media(max-width: $mobile) {
            font-size: 14px;
            gap: 16px;
        }

        span {
            color: var(--color-main);
        }
    }
}