.example__photo {
  height: 208px;
  border-radius: 4px;
  position: relative;

  img,
  .pdf-template {
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    aspect-ratio: 3/4;
  }

  &.example__photo-big {
    width: 100%;
    img {
      aspect-ratio: auto;
      width: 100%;
    }
  }

  &-interior {
    img {
      aspect-ratio: 4/3;
    }
  }

  .pdf-template {
    text-decoration: none;
    background-color: var(--green-200);

    &:hover {
      p {
        text-decoration: underline;
      }
    }
  }

  &__ero-status {
    background-color: var(--color-700-reverse);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 4px;
    font-size: 13px;
    border-radius: 0 0 4px 4px;
  }
}

.icon-remove {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--red-600);
  transition: background-color 0.4s;

  &:hover {
    background-color: var(--red-main);
  }
}

