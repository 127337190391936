@import "src/styles/variables";

.icon-payment {
    cursor: help;
}

.payment-methods {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    color: var(--color-main);
    transition: color var(--switch-theme-time);
    &__icon {
        width: 45px;
        height: 30px;
        padding: 3px;
        cursor: help;
        border-radius: 4px;
        background-color: var(--color-200);
        transition: background-color var(--switch-theme-time);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 100%;
            height: 100%;
        }

        @media (max-width: $mobile) {
            width: 35px
        }
    }
}

