@import "src/styles/variables";

.reviews {
  width: 100%;
  .rating-stars::before {
    background: linear-gradient(
      90deg,
      #ffcc4a calc(var(--percent) + 1px),
      var(--color-400) calc(var(--percent) + 1px)
    );
  }

  &__top {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: var(--color-main);
    flex-wrap: wrap;
    @media (max-width: $mobile) {
      margin-bottom: 12px;
    }
  }

  h3 {
    margin: 0;
    margin-right: auto;
    @media(min-width: $mobile){
      font-size: 18px;
    }

    span {
      @media (max-width: $mobile) {
        display: none;
      }
    }
  }

  &__stars {
    flex-wrap: wrap;
    display: flex;
    gap: 20px;
    color: var(--color-main);
    @media (max-width: $mobile) {
      gap: 16px;
    }
  }

  &__buttons{
    display: flex;
    gap: 12px;
  }

  &__total {
    display: flex;
    gap: 12px;
    align-items: center;

    .rating-stars {
      max-width: 100%;
      height: 20px;
      width: 110px;
      @media (max-width: $mobile) {
        height: 16px;
        width: 88px;
      }
    }

    > span {
      &:first-of-type {
        font-size: 24px;
        line-height: 1;
        @media (max-width: $mobile) {
          font-size: 18px;
        }
      }

      &:last-of-type {
        color: var(--color-600);
        white-space: nowrap;
        @media (min-width: $mobile) {
          display: none;
        }
      }
    }

    .rating__item {
      width: 22px;
      height: 20px;
      @media (max-width: $mobile) {
        width: 18px;
        height: 16px;
      }
    }
  }

  &__items {
    font-size: 13px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: var(--color-200);

    img {
      height: 12px;
      width: 66px;
    }
  }

  &__header{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 8px;
  }

  &__comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  &__comment {
    border-bottom: 1px solid var(--color-400);
    width: 100%;
  }

  &__day{
    margin-right: auto;
  }

  &__answer {
    padding-left: 24px;
    border-left: 1px solid var(--green-main);
  }
}
