@import "./src/styles/variables.scss";

.subs{
    &__title{
        margin-bottom: 24px;
        h1{
            margin: 0;
            margin-bottom: 16px;
        }
        
    }

    &__search,
    &__grid{
        gap: 16px;
        display: flex;
        flex-direction: column;
        @media(min-width: $mobile){
            display: grid;
            gap: 24px;
            grid-template-columns: repeat(2, 1fr);
        }
        @media(min-width: $tablet){
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__search{
        margin-bottom: 16px;
        @media(max-width: $mobile){
            flex-direction: column-reverse;
        }


        label.input-label:not(.subs-search label){
            width: 100%;
            padding: 6px 12px;
            max-height: 40px;
            margin-top: 10px;
            outline: none;
            border: 1px solid var(--color-400);
        }

        .input-label{
            &:last-of-type{
                width: 282px;
                @media(max-width: $mobileSmall){
                    width: 100%;
                    justify-content: flex-start;
                }
            }

        }
    }

    &-item{
        padding: 22px;
        border-radius: 20px;
        background-color: var(--color-300);
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media(max-width: $mobile){
            padding: 12px;
        }
        h3{
            margin: 0;
            display: flex;
            gap: 12px;
            align-items: center;

            button{
                padding: 6px;
                width: 40px;
            }
        }

        &__tags{
            display: flex;
            gap: 4px;
            flex-wrap: wrap;
            overflow: hidden;
            height: 52px;
            &._show{
                height: auto;
            }
        }
        &__tag{
            padding: 4px 8px;
            color: var(--color-600);
            background: var(--color-200);
            height: 24px;
            gap: 8px;
            span{
                color: var(--color-main);
            }
        }

        &__notify{
            display: flex;
            gap: 12px;
            flex-wrap: wrap;
            margin-top: auto;
            > div{
                display: flex;
                gap: 12px;
            }
        }
    }
}