@import "src/styles/variables";

.block-wrapper{
    background-color: var(--color-200);
    border-radius: 24px;
    padding: 24px;
    @media (max-width: $mobile) {
        padding: 16px;
    }

    &--operator{
        display: grid;
        grid-template-columns: 0.3fr 1fr;
        gap: 16px;
        @media (max-width: $mobile) {
            padding: 12px;
            gap: 10px;
        }
        &__name{
            display: flex;
            gap: 32px;
            @media (max-width: $mobile) {
                gap: 16px;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        &__wrapper{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 16px;
            @media (max-width: $mobile) {
                flex-direction: column-reverse;
            }
        }
    }
}