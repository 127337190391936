@import "src/styles/variables";

.best-agency {
    &__container {
        padding-top: 31px;
        padding-bottom: 31px;
        @media(max-width: $mobile) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .title_h1 {
            margin-bottom: 48px;
            @media(max-width: $mobile) {
                margin-bottom: 40px;
            }
        }
    }
    &__button{
        margin: 32px auto 0;
        text-decoration: none;
        justify-content: center;
        width: 300px;
    }
}
