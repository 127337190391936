
.rating-stars {
    width: 66px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    > div{
        width: 100%;
        height: 100%;
    }

    .rr--box:focus, .rr--box:focus-visible, .rr-reset:focus-visible, .rr-reset:focus {
        outline: none;
        box-shadow: none
    }

    .rr--fx-colors .rr--svg {
        transition-duration: .2s;
        transition-timing-function: var(--rr--easing);
        transition-property: background-color, border-color, fill, stroke
    }

    .rr--svg-stop-1 {
        stop-color: var(--color-main)
    }

    .rr--hf-svg-on .rr--svg, .rr--on .rr--svg{
        fill: var(--color-main) ;
        .rating-stars--gold & {
            fill: var(--gold-second) !important;
        }
        .rating-stars--green & {
            fill: var(--green-main) !important;
        }
    }

    .rr--hf-svg-off, .rr--off .rr--svg{
        fill: var(--color-500)
    }

    [dir=rtl] .rr--svg-stop-1, .rr--svg-stop-2 {
        stop-color: var(--color-500, rgba(0, 0, 0, 0));
    }
    &--gold{
        .rr--svg-stop-1 {
            stop-color: var(--gold-second)
        }
          .rr--hf-svg-on .rr--svg, .rr--on .rr--svg{
              fill: var(--gold-second) !important;
          }
    }

    &--green{
        .rr--svg-stop-1 {
            stop-color: var(--green-main)
        }
        .rr--hf-svg-on .rr--svg, .rr--on .rr--svg{
            fill: var(--green-main) !important;
        }
    }
}