@import "src/styles/variables";

.filter {
  display: block;
  padding: 30px 0 76px;
  //z-index: 5;

  .button__close{
    top: 20px;
    right: 20px;

    @media (max-width: $mobile) {
      top: 10px;
    }
  }

  .checkbox {
    input {
      pointer-events: none;
    }
  }
  &__body {
    padding: 0;
    margin: 0 auto;
    transition: all 0.2s ease;
    width: 600px;
    color: var(--color-main);
    @media (max-width: $mobile) {
      width: 360px;
    }

    .filter__top {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 10px;

      .select-countries{
        button.select-city{
          margin-left: 8px;
          border: 1px solid var(--color-400);
        }
      }
    }

    > .filter__box {
      &:nth-child(n-3) {
        padding: 20px;
      }
    }

    .checkbox._no-border {
      .icon--check {
        pointer-events: none;
      }
    }
  }

  .checkbox._no-border,
  .checkbox-button {
    @media (max-width: $mobile) {
      font-size: 13px;
    }
  }

  &__top {
    border-bottom: 1px solid var(--color-300);
  }

  .select {
    color: var(--color-main);
    margin-right: 0;
    min-width: 208px;
    padding: 10px 8px 10px 12px;
    .text-dots {
      max-width: 100%;
    }
    @media (max-width: $mobile) {
      // min-width: 171px;
    }
    &__flag {
      width: 28px;
      height: 20px;
    }
    &__arrow {
      width: 20px;
      height: 20px;
      margin-left: auto;
    }
  }

  .range {
    width: 264px;
    @media (max-width: $mobile) {
      width: 100%;
    }

    &__title {
      font-size: 14px;
    }
  }

  &__title {
    display: block;
    width: 100%;
    font-weight: 600;
  }

  &__checkbox-buttons {
    display: flex;
    gap: 4px;
    width: 100%;

    .checkbox-button{
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  .checkbox-button{
    text-wrap: nowrap;
    padding: 14px 25px;
    text-align: center;
    align-items: center;
    justify-content: center;
    @media (max-width: $mobile) {
      padding: 10px 16px;
    }
    
  }

  &__checkboxes-grid {
    display: grid;
    grid-template-columns: repeat(4, 140px);
    justify-items: start;
    grid-gap: 12px 0;
    width: 100%;
    margin-left: -8px;
    @media (max-width: $mobile) {
      grid-template-columns: repeat(2, 158px);
      grid-gap: 6px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    


    @media (max-width: $mobile) {
      height: 33px;
      .filter__title {
        padding: 13px;
      }
    }
  }

  &__box {
    padding: 12px 18px;
    border-bottom: 1px solid var(--color-300);
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: $mobile) {
      padding: 16px;
    }

    div.checkbox-button{
      label{
        padding: 0;
      }
    }
  }

  &--place {
    > span {
      display: flex;
      justify-content: space-between;
    }

    .filter__checkbox-buttons {
      @media (max-width: $mobile) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &--tag {
    display: flex;
    gap: 4px;
    flex-wrap: nowrap;

    .checkbox-button{
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  &--breast {
    justify-content: unset;
  }

  &--special {
    gap: 12px;

    .filter__checkbox-buttons {
      width: 466px;
      @media (max-width: $mobile) {
        width: 234px;
      }
    }

    .filter__title {
      width: auto;
    }
  }

  &--status,
  &--language,
  &--national {
    display: grid;
    grid-template-columns: repeat(3, 186px);
    justify-items: start;
    grid-gap: 12px 0;
    width: 100%;
    margin-left: -8px;

    .checkbox label{
      gap: 4px;
    }
    @media (max-width: $mobile) {
      grid-template-columns: 148px 168px;
      grid-gap: 12px;
      
    }
  }

  &--services {
    .filter__checkboxes-grid:not(.filter__checkboxes-grid:first-of-type) {
      grid-template-columns: repeat(2, 274px);
      grid-gap: 12px;
      @media (max-width: $mobile) {
        grid-template-columns: repeat(2, 158px);
        grid-gap: 6px;
      }
    }

    .filter__checkboxes-grid:not(.filter__checkboxes-grid:last-of-type) {
      margin-bottom: 4px;
    }
  }

  &--language {
    flex-wrap: nowrap;
    grid-auto-flow: dense;
  }
  &--last-visit {
    flex-wrap: nowrap;
    gap: 8px 4px;
  }

  &--last-visit {
    @media (max-width: $mobile) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--radius {
    gap: 12px;
  }

  &--gender {
    .filter__checkboxes-grid {
      @media (max-width: $mobile) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px 12px;
      }
    }
  }
  &--ethnos {
    .filter__checkboxes-grid {
      @media (max-width: $mobile) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px 0;
      }
    }
  }
  &--eye,
  &--hair {
    .filter__checkboxes-grid {
      @media (max-width: $mobile) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px 12px;
      }
    }
  }

  &__map-input {
    display: flex;
    width: 100%;
    gap: 8px;
    outline: 0;

    fieldset{
      flex: 1;
    }

    .button-square{
      margin-top: 10px;
      border: 1px solid var(--color-400);
      background-color: transparent;
      width: 40px;
      height: 40px;
      &:hover{
        background-color: var(--color-200);
      }
    }
    
    .header__search-input {
      margin-top: auto;
      min-height: inherit;
      padding: inherit;
      width: 85%;
      position: absolute;
      outline: none;
      border-radius: 0;
      background-color: transparent;
      color: var(--color-main);
      &::placeholder {
        color: var(--color-400);
      }
    }
    .geosuggest__suggests-wrapper {
      width: 100%;
      max-height: 200px;
      overflow: hidden;
      border-radius: 4px;
      background-color: var(--black-light);
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      ul:not(:empty) {
        padding: 5px;
        border-top: none;
      }
      li {
        cursor: pointer;
        color: var(--color-600);
        list-style: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .geosuggest__suggests--hidden {
      display: none;
    }
    .geosuggest__input{
      font-size: 15px;
      @media (max-width: $mobile) {
        font-size: 14px;
        margin-top: 3px;
        width: 79%;
      }
    }
    
  }

  &__map-title {
    color: var(--color-600);
    position: absolute;
    top: 10px;
    font-size: 13px;
    left: 16px;
    line-height: 120%;
    z-index: 1;
    @media (max-width: $mobile) {
      font-size: 12px;
    }

    .error {
      color: var(--red-700);
    }
  }

  &__bottom {
    display: flex;
    gap: 10px;
    padding: 10px;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    bottom: -77px;
    z-index: 1;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background-color: var(--black-light);
  }

  &__submit {
    width: 100%;
    justify-content: center;
  }
  &__reset {
    width: 30%;
    justify-content: center;
  }

  .filter__selected {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    line-height: 1;
    margin-left: 4px;
    font-size: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    color: var(--color-main-reverse);
    background: var(--color-main);
  }
}
